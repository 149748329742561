import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../redux/slices/school/selectors";

import NewClass from "../../../shared/components/classes/NewClass";
import ClassTable from "../../../shared/components/classes/ClassTable";
import useCreateSubClass from "../../../shared/api/classes/useCreateSubClass";
import useFetchSchoolClasses from "../../../shared/api/classes/useFetchSchoolClasses";

const Classes = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selected = query.get("selected");

  const { schoolId } = useParams();
  const navigate = useNavigate();
  const calendar = useSelector(selectCalendar);

  const [juniorClasses, seniorClasses, loading, fetchClasses] =
    useFetchSchoolClasses(calendar?.session);

  const [createNewClassRequest, newClassLoading] = useCreateSubClass();

  async function createNewClass() {
    await createNewClassRequest({ schoolId, category: selected });
    closeSideModal();
    await fetchClasses(calendar?.session);
  }

  function getTotalStudents(item) {
    if (!item) return 0;

    return item?.maleStudents + item?.femaleStudents;
  }

  function getTeacherDetails(item) {
    if (!item?.teacherFirstName && !item?.teacherLastName) return "N/A";

    const teacherName = `${item?.teacherFirstName || ""} ${
      item?.teacherLastName || ""
    }`;

    return teacherName;
  }

  function handleNewClassRequest(params) {
    if (newClassLoading) return;
    navigate(`/${schoolId}/classes?selected=${params?.category}`);
  }

  function closeSideModal() {
    if (newClassLoading) return;
    navigate(`/${schoolId}/classes`);
  }

  return (
    <div>
      <div className="page">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Classes</h1>
        </div>

        {/* Junior Calsses */}
        <ClassTable
          loading={loading}
          classes={juniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Junior Classes"}
          handleNewClass={handleNewClassRequest}
        />

        {/* Senior Classes */}
        <ClassTable
          loading={loading}
          classes={seniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Senior Classes"}
          handleNewClass={handleNewClassRequest}
        />
      </div>

      {/* Sidebar */}
      {selected ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${selected ? "block" : ""}`}>
        <NewClass
          category={selected}
          closeSideModal={closeSideModal}
          createNewClass={createNewClass}
          submitting={newClassLoading}
        />
      </div>
    </div>
  );
};
export default Classes;
