import React from "react";

import BillingInvoiceTable from "../../../../shared/components/settings/billing/BillingInvoiceTable";
import BillingNavigationBar from "../../../../shared/components/settings/billing/BillingNavigationBar";
import { useSelector } from "react-redux";
import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";
import useFetchSchoolInvoices from "../../../../shared/api/school/useFetchSchoolInvoices";

export default function BillingInvoices() {
  // const { schoolId } = useParams();
  const school = useSelector(selectSchoolDetails);
  const { data: invoices } = useFetchSchoolInvoices(school?.schoolId);

  return (
    <div>
      <div className="page">
        {/**
        |--------------------------------------------------
        | Page title
        |--------------------------------------------------
        */}
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Billing Invoices</h1>
        </div>

        {/**
        |--------------------------------------------------
        | Navigation bar
        |--------------------------------------------------
        */}
        <BillingNavigationBar activeIndex={2} />

        {/**
        |--------------------------------------------------
        | Main content
        |--------------------------------------------------
        */}
        <div className="main_content !pt-0 !pb-0 mb-10">
          {/**
            |--------------------------------------------------
            | Billing Overview
            |--------------------------------------------------
            */}
          <BillingInvoiceTable data={invoices} />
        </div>
      </div>
    </div>
  );
}
