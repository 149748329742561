import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useEditAcademicSession from "../../../api/curriculum/academicSessions/useEditAcademicSession";

export default function EditAcademicSession({
  closeSideModal,
  academicSession,
  loadingAcademicSession,
}) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useEditAcademicSession();

  useEffect(() => {
    setPayload({
      session: academicSession?.session || "",
      nextSession: academicSession?.nextSession || "",
    });
  }, [academicSession]);

  async function createAcademicSession() {
    if (!payload?.session) return toast("Session year is required");
    if (!payload?.nextSession) return toast("Next session is required");

    const isSuccess = await request(
      academicSession?.academicSessionId,
      payload
    );

    if (isSuccess) {
      setPayload({});
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Edit AcademicSession</h3>
      </div>

      {loadingAcademicSession ? (
        <Loader loading={loadingAcademicSession} />
      ) : (
        <>
          <div className="border-t pt-5">
            <FormInput
              required
              label={"Session Year"}
              value={payload?.session || ""}
              placeholder={"YYYY-YYYY"}
              onChange={(e) =>
                setPayload({ ...payload, name: e.currentTarget.value })
              }
            />

            <FormInput
              required
              placeholder={"YYYY-YYYY"}
              label={"Next Session Year"}
              value={payload?.nextSession || ""}
              onChange={(e) =>
                setPayload({ ...payload, nextSession: e.currentTarget.value })
              }
            />
          </div>

          <div className="mt-10">
            <FormButton
              title={"Edit Academic Session"}
              onClick={createAcademicSession}
              loading={loading}
            />

            <FormButton
              title={"Cancel"}
              onClick={closeSideModal}
              className={
                "!bg-transparent !text-coral_red !border-coral_red mt-3"
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
