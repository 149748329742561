import { useState } from "react";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import { useSelector } from "react-redux";
import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";
import useAddSubjectToSchoolCurriculum from "../../../api/school/useAddSubjectToSchoolCurriculum";

const AddSubjectToCurriculum = ({
  schoolSubjects,
  curriculum,
  curriculumLoading,
  schoolSubjectsLoading,
  closeSideModal,
  action,
  refresh,
  category,
}) => {
  const { schoolId } = useParams();
  const navigate = useNavigate();
  const school = useSelector(selectSchoolDetails);

  const [addSubjectRequest, addingSubject] = useAddSubjectToSchoolCurriculum();
  const [selectedSubject, setSelectedSubject] = useState(null);

  function handleCancel() {
    if (selectedSubject) return setSelectedSubject(null);

    closeSideModal();
  }

  async function addSubject(selectedSubject) {
    // if subject have not been sorted, navigate to sort subject page
    if (category === "junior" && !school?.hasSortJuniorCurriculum) {
      toast("Please select subjects for junior classes");

      return navigate(`/${schoolId}/settings/curriculum/junior?action=sort`);
    }

    if (category === "senior" && !school?.hasSortSeniorCurriculum) {
      toast("Please select subjects for senior classes");

      return navigate(`/${schoolId}/settings/curriculum/senior?action=sort`);
    }

    if (addingSubject) return;

    const isSuccess = await addSubjectRequest(schoolId, {
      subjectId: selectedSubject?.subjectId,
    });
    if (isSuccess) {
      await refresh(category);
      closeSideModal();
    }
  }

  if (action !== "add") return null;

  if (schoolSubjectsLoading || curriculumLoading)
    return <Loader loading={schoolSubjectsLoading || curriculumLoading} />;

  return (
    <div>
      <div className="mb-6">
        <h3 className="side_page_title mb-1">New Junior Subject</h3>

        <p className="text-sm text-independence">
          Add new subject to your junior school curriculum
        </p>
      </div>

      <div>
        {!curriculumLoading &&
          !selectedSubject &&
          curriculum?.map((subject, i) => {
            const index = schoolSubjects?.findIndex(
              (sub) => sub?.subjectId === subject?.subjectId
            );

            return (
              <div
                key={i}
                className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer ${
                  index >= 0 ? "bg-primary" : "bg-cultured "
                }`}
                onClick={() => {
                  if (index >= 0) return;

                  setSelectedSubject(subject);
                }}
              >
                <p
                  className={`text-sm font-semibold capitalize ${
                    index >= 0 ? "text-white" : "text-yankee_blue"
                  }`}
                >
                  {subject?.name}
                </p>
              </div>
            );
          })}

        {!curriculumLoading && selectedSubject && (
          <div>
            {/* <h4 className="text-yankee_blue text-base mb-1">
              Do you want to add subject to your school curriculum?
            </h4> */}

            <div className=" mb-7">
              <FormInput
                placeholder={selectedSubject?.name}
                disabled={true}
                className="mb-2"
              />

              <p className="text-independence text-sm">
                Subject will be added to all classes
              </p>
            </div>

            <FormButton
              title={"Add Subject"}
              onClick={() => {
                addSubject(selectedSubject);
                setSelectedSubject(null);
              }}
              loading={addingSubject}
            />
          </div>
        )}
      </div>

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancel}
      >
        Cancel
      </p>
    </div>
  );
};

export default AddSubjectToCurriculum;
