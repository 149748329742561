import { toast } from "react-toastify";
import React, { useState } from "react";

import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useCreateSubject from "../../../api/curriculum/subjects/useCreateSubject";

export default function NewSubject({ closeSideModal, category }) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useCreateSubject();

  async function createSubject() {
    if (!payload?.name) return toast("Name is required");
    if (category === "junior" && !payload?.group)
      return toast("Group is required");
    if (category === "senior" && !payload?.department)
      return toast("Department is required");

    const isSuccess = await request(category, payload);

    if (isSuccess) {
      setPayload({});
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Add Subject to Curriculum</h3>
      </div>

      <div className="border-t pt-5">
        <FormInput
          placeholder={"Enter subject name"}
          required
          onChange={(e) =>
            setPayload({ ...payload, name: e.currentTarget.value })
          }
        />

        {category === "junior" ? (
          <SelectInput
            label={"Subject group"}
            placeholder="Choose a subject group"
            handleSelect={(e) =>
              setPayload({ ...payload, group: e.currentTarget.value })
            }
            options={[
              { name: "General", value: "General" },
              { name: "Language", value: "Language" },
              {
                name: "Pre-Vocational Studies",
                value: "Pre-Vocational Studies",
              },
              { name: "Religious Studies", value: "Religious Studies" },
              {
                name: "National Values Education",
                value: "National Values Education",
              },
            ]}
          />
        ) : null}

        {category === "senior" ? (
          <SelectInput
            label={"Department"}
            placeholder="Choose subject department"
            handleSelect={(e) =>
              setPayload({ ...payload, department: e.currentTarget.value })
            }
            options={[
              { name: "General", value: "General" },
              { name: "Art", value: "Art" },
              { name: "Commercial", value: "Commercial" },
              { name: "Science", value: "Science" },
            ]}
          />
        ) : null}
      </div>

      <div className="mt-10">
        <FormButton
          title={"Add Subject"}
          onClick={createSubject}
          loading={loading}
        />

        <FormButton
          title={"Cancel"}
          onClick={closeSideModal}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
        />
      </div>
    </div>
  );
}
