import { useState } from "react";
import { toast } from "react-toastify";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useDeleteAcademicSessionById from "../../../api/curriculum/academicSessions/useDeleteAcademicSessionById";

const DeleteAcademicSession = ({
  closeSideModal,
  academicSession,
  loadingAcademicSession,
}) => {
  const { loading, request } = useDeleteAcademicSessionById();
  const [text, setText] = useState("");

  async function handleDelete() {
    if (!text || text !== academicSession?.session)
      return toast("Enter correct session year");

    const isSuccessful = await request(academicSession?.academicSessionId);

    if (isSuccessful) {
      setText(null);
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div>
      <div>
        <h2 className="side_page_title">Delete Academic Session</h2>
        <p className="text-sm text-independence">
          Are you sure you want to delete academic session? <br /> This action
          cannot be undone
        </p>
      </div>

      {loadingAcademicSession ? (
        <Loader loading={loadingAcademicSession} />
      ) : (
        <div className="mt-8">
          <FormInput
            placeholder={academicSession?.session}
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
            className="mb-2"
          />

          <p className="text-sm text-independence">
            Enter "
            <span className="text-black font-bold">
              {academicSession?.session}
            </span>
            " to permanently delete session
          </p>

          <FormButton
            title={"Delete"}
            className={"!bg-coral_red mt-5"}
            loading={loading}
            onClick={handleDelete}
          />

          <FormButton
            title={"Cancel"}
            className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
            onClick={closeSideModal}
          />
        </div>
      )}
    </div>
  );
};

export default DeleteAcademicSession;
