import { useParams } from "react-router-dom";

import PageLoader from "../../../../shared/components/PageLoader";
import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import BasicDetailsForm from "../../../../shared/components/classes/editStudent/BasicDetailsForm";
import MedicalDetailsForm from "../../../../shared/components/classes/editStudent/MedicalDetailsForm";
import GuardianDetailsForm from "../../../../shared/components/classes/editStudent/GuardianDetailsForm";

const EditStudent = () => {
  const { classId, studentId } = useParams();

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [studentLoading, studentDetails] = useFetchStudentBasicInfo(studentId);

  if (classLoading || studentLoading) {
    return <PageLoader loading={classLoading || studentLoading} />;
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5 capitalize">
          {studentDetails?.lastName} {studentDetails?.firstName}{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <div className="main_content mb-10">
          <BasicDetailsForm
            category={classDetails?.category}
            details={studentDetails}
          />
        </div>

        <div className="main_content mb-10">
          <GuardianDetailsForm />
        </div>

        <div className="main_content mb-10">
          <MedicalDetailsForm details={studentDetails} />
        </div>
      </div>
    </div>
  );
};

export default EditStudent;
