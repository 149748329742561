import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import Subjects from "../../../../shared/components/classes/scorecards/Subjects";
import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
// import ClassNavigationBar from "../../../../shared/components/classes/ClassNavigationBar";
import EditScorecard from "../../../../shared/components/classes/scorecards/EditScorecard";
import ScorecardTable from "../../../../shared/components/classes/scorecards/ScorecardTable";
import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import ScorecardOverview from "../../../../shared/components/classes/scorecards/ScorecardOverview";
import useFetchScorecardsByClassSubjectId from "../../../../shared/api/cards/useFetchScorecardsByClassSubjectId";

// TODO: handle subject selection to be static if scorecard or class subject is selected
const Scorecards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const scorecardId = query.get("scorecardId");
  const action = query.get("action");

  const navigate = useNavigate();

  const { classId, schoolId } = useParams();
  const calendar = useSelector(selectCalendar);

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [scorecards, loading, fetchSubjectScorecard] =
    useFetchScorecardsByClassSubjectId(
      selectedSubject?.classSubjectId,
      calendar
    );

  function handleSelectSubject(params) {
    setSelectedSubject(params);
    closeSideModal();
  }

  function handleShowSubjectModal() {
    navigate(`/${schoolId}/classes/${classId}/scorecards?action=show-subjects`);
  }

  function closeSideModal() {
    navigate(`/${schoolId}/classes/${classId}/scorecards`);
  }

  function handleEditScorecard(card) {
    navigate(
      `/${schoolId}/classes/${classId}/scorecards?scorecardId=${card?.scorecardId}`
    );
  }

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">Scorecards</h1>

            <p className="page_subtitle">
              {classDetails
                ? `(${classDetails?.category} ${classDetails?.suffix})`
                : null}
            </p>
          </div>

          <button
            className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
            onClick={handleShowSubjectModal}
          >
            <small>Select Subject</small>
          </button>
        </div>

        {/* <ClassNavigationBar activeIndex={4} /> */}

        <div className="main_content">
          <ScorecardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            selectedSubject={selectedSubject}
            scorecardLength={scorecards?.length}
            subjectLength={subjects?.length}
          />

          <ScorecardTable
            data={scorecards}
            loading={loading}
            handleEditScorecard={handleEditScorecard}
            handleShowSubjectModal={handleShowSubjectModal}
            scorecardId={scorecardId}
          />
        </div>
      </div>

      {scorecardId || action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${scorecardId || action ? "block" : ""}`}>
        <EditScorecard
          scorecardId={scorecardId}
          fetchSubjectScorecard={fetchSubjectScorecard}
          handleCancelEditScorecard={closeSideModal}
        />

        <Subjects
          closeSideModal={closeSideModal}
          action={action}
          subjects={subjects}
          handleSelectSubject={handleSelectSubject}
          selectedSubject={selectedSubject}
          subjectLoading={subjectLoading}
        />
      </div>
    </div>
  );
};
export default Scorecards;
