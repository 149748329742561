import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
// import ClassNavigationBar from "../../../../shared/components/classes/ClassNavigationBar";
import useFetchClassReportCard from "../../../../shared/api/cards/useFetchClassReportCard";
import ReportCardTable from "../../../../shared/components/classes/reportCards/ReportCardTable";
import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import ReportCardOverview from "../../../../shared/components/classes/reportCards/ReportCardOverview";

const ReportCards = () => {
  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const currentCalendar = useSelector(selectCalendar);

  const [selectedTerm, setSelectedTerm] = useState("");
  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects] = useFetchClassSubjects(classId);

  const [reportCards, reportCardsLoading] = useFetchClassReportCard(
    currentCalendar?.session,
    selectedTerm
  );

  useEffect(() => {
    setSelectedTerm(currentCalendar?.term);
  }, [currentCalendar]);

  function handleSelect(params) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${params?.studentId}/reportcard`
    );
  }

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">Report Cards</h1>

            <p className="page_subtitle">
              {classDetails
                ? `(${classDetails?.category} ${classDetails?.suffix})`
                : null}
            </p>
          </div>
        </div>

        {/* <ClassNavigationBar activeIndex={5} /> */}

        <div className="main_content">
          <ReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            subjectLength={subjects?.length}
            reportCardsLength={reportCards?.length}
            handleSelectCalendar={setSelectedTerm}
            selectedTerm={selectedTerm}
            currentCalendar={currentCalendar}
          />

          <ReportCardTable
            data={reportCards}
            loading={reportCardsLoading}
            subjectLoading={subjectLoading}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportCards;
