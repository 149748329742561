import { handleToast } from "../utils/functions";

export default function handleApiError(error) {
  if (error?.message?.toLowerCase()?.includes("network error")) {
    return handleToast("Network unvailable", "error");
  } else if (error?.response?.status === 401) {
    localStorage.removeItem("token");
    handleToast(error?.response?.data?.message, "error");
    return window.history.replaceState("", "", "/login");
    // return redirect("/login");
  } else if (error?.response?.status >= 400) {
    return handleToast(error?.response?.data?.message, "error");
  } else if (error?.message?.toLowerCase()?.includes("timeout")) {
    return handleToast("The server took too long to respond", "error");
  } else {
    return handleToast("Something went wrong", "error");
  }
}
