import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NewSubject from "../../../shared/components/curriculum/subjects/NewSubject";
import EditSubject from "../../../shared/components/curriculum/subjects/EditSubject";
import SubjectTable from "../../../shared/components/curriculum/subjects/SubjectTable";
import DeleteSubject from "../../../shared/components/curriculum/subjects/DeleteSubject";
import SubjectOverview from "../../../shared/components/curriculum/subjects/SubjectOverview";
import useFetchAllSubjects from "../../../shared/api/curriculum/subjects/useFetchAllSubjects";
import useFetchSubjectById from "../../../shared/api/curriculum/subjects/useFetchSubjectById";

export default function SeniorCurriculum() {
  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const selected = query.get("selected");
  const action = query.get("action");

  /**
  |--------------------------------------------------
  | Api hook to get all subjects
  |--------------------------------------------------
  */
  const { loading, data: subjects, request } = useFetchAllSubjects("senior");

  const { loading: loadingSubject, subject } = useFetchSubjectById(
    "senior",
    selected
  );

  const handleEditSubject = (subject) => {
    navigate(`/curriculum/senior?selected=${subject?.subjectId}&action=edit`);
  };

  const handleDeleteSubject = (subject) => {
    navigate(`/curriculum/senior?selected=${subject?.subjectId}&action=delete`);
  };

  const closeSideModal = (param) => {
    if (param?.refresh) request("senior");

    navigate(`/curriculum/senior`);
  };

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <h1 className="page_title mb-1">Senior Subjects</h1>

          <button
            className="bg-primary text-white px-3 py-1  rounded-sm"
            onClick={() => {
              navigate(`/curriculum/senior?action=new-subject`);
            }}
          >
            <small className="text-xs">New Subject</small>
          </button>
        </div>

        <div className="main_content">
          <SubjectOverview totalSubjects={subjects?.length} />

          <SubjectTable
            data={subjects}
            loading={loading}
            selected={selected}
            handleEditSubject={handleEditSubject}
            handleDeleteSubject={handleDeleteSubject}
          />
        </div>
      </div>

      {selected ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${selected ? "block" : ""}`}>
        {action === "new-subject" ? (
          <NewSubject
            selected={selected}
            category={"senior"}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {selected && action === "edit" && subject ? (
          <EditSubject
            subject={subject}
            category={"senior"}
            loadingSubject={loadingSubject}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {selected && action === "delete" && subject ? (
          <DeleteSubject
            subject={subject}
            category={"senior"}
            loadingSubject={loadingSubject}
            closeSideModal={closeSideModal}
          />
        ) : null}
      </div>
    </div>
  );
}
