import { toast } from "react-toastify";
import React, { useState } from "react";

import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import SelectInput from "../form/SelectInput";
import useCreateCalendarEvent from "../../api/calendar/useCreateCalendarEvent";

export default function AddEvent({ calendar, closeSideModal }) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useCreateCalendarEvent();

  async function createEvent() {
    if (!calendar) return toast("Calendar is required");
    if (!payload?.title) return toast("Title is required");
    if (!payload?.startDate) return toast("Start date is required");
    if (!payload?.endDate) return toast("End Date is required");

    const isSuccess = await request({
      ...payload,
      calendarId: calendar?.calendarId,
      category: "state",
    });
    if (isSuccess) {
      setPayload({});
      closeSideModal();
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Add Event to calendar</h3>
      </div>

      <div className="border-t pt-5">
        <FormInput
          label={"Event Title"}
          placeholder="Enter Title"
          value={payload?.title || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              title: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Start Date"}
          placeholder="Choose Beginning of vacation"
          type="date"
          value={payload?.startDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              startDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"End date"}
          placeholder="Choose end of vacation"
          type="date"
          value={payload?.endDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              endDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <SelectInput
          placeholder="Select event type"
          label={"Is event an holiday"}
          required
          value={payload?.isHoliday || ""}
          handleSelect={(e) =>
            setPayload({
              ...payload,
              isHoliday: e.currentTarget.value,
            })
          }
          options={[
            { name: "Yes", value: true },
            { name: "No", value: false },
          ]}
          className="mb-2"
        />
      </div>

      <div className="mt-10">
        <FormButton
          title={"Create Event"}
          onClick={createEvent}
          loading={loading}
        />

        <FormButton
          title={"Cancel"}
          onClick={closeSideModal}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
        />
      </div>
    </div>
  );
}
