import { toast } from "react-toastify";
import { useCallback, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useCreateCalendarEvent() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);

      const res = await client.post("/calendar-event", payload);

      toast(res.data?.message);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
