import { useCallback, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";
import { handleToast } from "../../../utils/functions";

export default function useEditSubject() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (category, id, payload) => {
    try {
      setLoading(true);

      const res = await client.patch(`/curriculum/${category}/${id}`, payload);

      handleToast(res.data?.message);
      setLoading(false);

      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { loading, request };
}
