import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { FiUsers } from "react-icons/fi";
import { useSelector } from "react-redux";
import { BiCabinet } from "react-icons/bi";
import { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { GoDeviceDesktop } from "react-icons/go";
import { AiOutlineSetting } from "react-icons/ai";
import { BiCalendar, BiLogOut } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import { NavLink, useLocation, useParams } from "react-router-dom";

import Logo from "./Logo";
import { selectTotalUnreadNotifications } from "../../redux/slices/notification/selectors";

const SchoolSidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const { schoolId } = useParams();

  const [activeTab, setActiveTab] = useState("");
  const totalUnreadNotif = useSelector(selectTotalUnreadNotifications);

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  // const routes = [
  //   {
  //     activeIndex: 2,
  //     label: "Dashboard",
  //     icon: <RxDashboard />,
  //     activeIndexLabel: "dashboard",
  //     route: `/${schoolId}/dashboard`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Teachers",
  //     icon: <FiUsers />,
  //     activeIndexLabel: "teachers",
  //     route: `/${schoolId}/teachers`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Classes",
  //     icon: <GoDeviceDesktop />,
  //     activeIndexLabel: "classes",
  //     route: `/${schoolId}/classes`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Archives",
  //     icon: <BiCabinet />,
  //     activeIndexLabel: "archives",
  //     submenus: [
  //       {
  //         activeIndex: 3,
  //         label: " Report Cards",
  //         activeIndexLabel: "reportcards",
  //         route: `/${schoolId}/archives/classes`,
  //       },
  //     ],
  //   },
  // ];

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-8 py-5 mb-5 border-b ">
        <Logo />
      </div>

      <Menu
        menuItemStyles={{
          button: {
            [`&`]: {
              height: "40px",
            },
          },
        }}
        stye
      >
        <MenuItem
          icon={<RxDashboard />}
          component={<NavLink to={`/${schoolId}/dashboard`} />}
          className={`${
            activeTab?.[2] === "dashboard"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Dashboard
        </MenuItem>

        <MenuItem
          icon={<FiUsers />}
          component={<NavLink to={`/${schoolId}/teachers`} />}
          className={`${
            activeTab?.[2] === "teachers"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Teachers
        </MenuItem>

        <MenuItem
          icon={<GoDeviceDesktop />}
          component={<NavLink to={`/${schoolId}/classes`} />}
          className={`${
            activeTab?.[2] === "classes" ? "sidebar_active" : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Classes
        </MenuItem>

        <SubMenu
          label={"Archives"}
          className={`${
            activeTab?.[2] === "archives"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          icon={<BiCabinet />}
        >
          <MenuItem
            component={<NavLink to={`/${schoolId}/archives/classes`} />}
            className={`${
              activeTab?.[3] === "reportcards"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Report Cards
          </MenuItem>

          <MenuItem
            component={
              <NavLink to={`/${schoolId}/archives/graduated-students/junior`} />
            }
            className={`${
              activeTab?.[3] === "graduated-students"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Graduated Students
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/${schoolId}/archives/left-students`} />}
            className={`${
              activeTab?.[3] === "left-students"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Left Students
          </MenuItem>
        </SubMenu>

        {/* <MenuItem
          icon={<HiOutlineSpeakerWave />}
          component={<NavLink to={`/${schoolId}/announcements`} />}
          className={`${
            activeTab?.[2] === "announcements"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Announcement
        </MenuItem> */}

        <MenuItem
          icon={<IoNotificationsOutline />}
          component={<NavLink to={`/${schoolId}/notifications`} />}
          className={`${
            activeTab?.[2] === "notifications"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Notification
          <span className="ml-5 bg-coral_red text-white text-xxs p-[3px] rounded-md">
            {totalUnreadNotif}
          </span>
        </MenuItem>

        <MenuItem
          icon={<BiCalendar />}
          component={<NavLink to={`/${schoolId}/calendar`} />}
          className={`${
            activeTab?.[2] === "calendar"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Calendar
        </MenuItem>

        <SubMenu
          label={"Settings"}
          className={`${
            activeTab?.[2] === "settings"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          icon={<AiOutlineSetting />}
        >
          <MenuItem
            component={<NavLink to={`/${schoolId}/settings/billing`} />}
            className={`${
              activeTab?.[3] === "billing"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Billing
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/${schoolId}/settings/admin`} />}
            className={`${
              activeTab?.[3] === "admin" ? "sidebar_active" : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Admin Profile
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/${schoolId}/settings/school`} />}
            className={`${
              activeTab?.[3] === "school"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            School Details
          </MenuItem>

          <MenuItem
            component={
              <NavLink to={`/${schoolId}/settings/curriculum/junior`} />
            }
            className={`${
              activeTab?.[4] === "junior"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Junior Curriculum
          </MenuItem>

          <MenuItem
            component={
              <NavLink to={`/${schoolId}/settings/curriculum/senior`} />
            }
            className={`${
              activeTab?.[4] === "senior"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Senior Curriculum
          </MenuItem>
        </SubMenu>

        <MenuItem
          component={<NavLink to={`/users/school-owners`} />}
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
        >
          Exit School
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default SchoolSidebar;
