import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useEditSubject from "../../../api/curriculum/subjects/useEditSubject";

export default function EditSubject({
  subject,
  category,
  loadingSubject,
  closeSideModal,
}) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useEditSubject();

  useEffect(() => {
    setPayload({
      name: subject?.name || "",
      group: subject?.group || "",
      department: subject?.department || "",
    });
  }, [subject]);

  async function createSubject() {
    if (!payload?.name) return toast("Name is required");
    if (category === "junior" && !payload?.group)
      return toast("Group is required");
    if (category === "senior" && !payload?.department)
      return toast("Department is required");

    const isSuccess = await request(category, subject?.subjectId, payload);

    if (isSuccess) {
      setPayload({});
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Edit Subject</h3>
      </div>

      {loadingSubject ? (
        <Loader loading={loadingSubject} />
      ) : (
        <>
          <div className="border-t pt-5">
            <FormInput
              required
              value={payload?.name}
              label={"Subject name"}
              placeholder={"Enter subject name"}
              onChange={(e) =>
                setPayload({ ...payload, name: e.currentTarget.value })
              }
            />

            {category && category === "junior" ? (
              <SelectInput
                label={"Subject group"}
                value={payload?.group || ""}
                placeholder="Choose a subject group"
                handleSelect={(e) =>
                  setPayload({ ...payload, group: e.currentTarget.value })
                }
                options={[
                  { name: "General", value: "general" },
                  { name: "Language", value: "language" },
                  {
                    name: "Pre-Vocational Studies",
                    value: "pre-vocational studies",
                  },
                  { name: "Religious Studies", value: "religious studies" },
                  {
                    name: "National Values Education",
                    value: "national values education",
                  },
                ]}
              />
            ) : null}

            {category && category === "senior" ? (
              <SelectInput
                label={"Department"}
                value={payload?.department || ""}
                placeholder="Choose subject department"
                handleSelect={(e) =>
                  setPayload({ ...payload, department: e.currentTarget.value })
                }
                options={[
                  { name: "General", value: "general" },
                  { name: "Art", value: "art" },
                  { name: "Commercial", value: "commercial" },
                  { name: "Science", value: "science" },
                ]}
              />
            ) : null}
          </div>

          <div className="mt-10">
            <FormButton
              title={"Edit Subject"}
              onClick={createSubject}
              loading={loading}
            />

            <FormButton
              title={"Cancel"}
              onClick={closeSideModal}
              className={
                "!bg-transparent !text-coral_red !border-coral_red mt-3"
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
