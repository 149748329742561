import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import SelectInput from "../form/SelectInput";
import useUpdateEvent from "../../api/calendar/events/useUpdateEvent";
import useFetchEventById from "../../api/calendar/events/useFetchEventById";
import Loader from "../Loader";

export default function EditEvent({ calendar, closeSideModal, eventId }) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useUpdateEvent();
  const { event, loading: loadingEvent } = useFetchEventById(eventId);

  useEffect(() => {
    setPayload({
      startDate: moment(event?.startDate).format("YYYY-MM-DD"),
      endDate: moment(event?.endDate).format("YYYY-MM-DD"),
      startTime: moment(event?.startTime, "HHmm").format("HH:mm"),
      endTime: moment(event?.endTime, "HHmm").format("HH:mm"),
      title: event?.title,
      isHoliday: event?.isHoliday,
      calendarEventId: event?.calendarEventId,
    });
  }, [event]);

  async function createEvent() {
    if (!calendar) return toast("Calendar is required");
    if (!payload?.title) return toast("Title is required");
    if (!payload?.startDate) return toast("Start date is required");
    if (!payload?.endDate) return toast("End Date is required");

    const isSuccess = await request({
      ...payload,
      calendarId: calendar?.calendarId,
    });

    if (isSuccess) {
      setPayload({});
      closeSideModal();
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Edit Calendar Event</h3>
      </div>

      {loadingEvent ? (
        <Loader loading={loading} />
      ) : (
        <>
          <div className="border-t pt-5">
            <FormInput
              label={"Event Title"}
              placeholder="Enter Title"
              value={payload?.title || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  title: e.currentTarget.value,
                })
              }
              className="mb-2"
            />

            <FormInput
              label={"Start Date"}
              placeholder="Choose Beginning of vacation"
              type="date"
              value={payload?.startDate || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  startDate: e.currentTarget.value,
                })
              }
              className="mb-2"
            />

            <FormInput
              label={"End date"}
              placeholder="Choose end of vacation"
              type="date"
              value={payload?.endDate || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  endDate: e.currentTarget.value,
                })
              }
              className="mb-2"
            />

            <SelectInput
              placeholder="Select event type"
              label={"Is event an holiday"}
              required
              value={payload?.isHoliday || ""}
              handleSelect={(e) =>
                setPayload({
                  ...payload,
                  isHoliday: e.currentTarget.value,
                })
              }
              options={[
                { name: "Yes", value: true },
                { name: "No", value: false },
              ]}
              className="mb-2"
            />
          </div>

          <div className="mt-10">
            <FormButton
              title={"Edit Event"}
              onClick={createEvent}
              loading={loading}
            />

            <FormButton
              title={"Cancel"}
              onClick={closeSideModal}
              className={
                "!bg-transparent !text-coral_red !border-coral_red mt-3"
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
