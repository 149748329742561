import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectJuniorSubjects } from "../../../../redux/slices/school/selectors";
import { selectJuniorCurriculums } from "../../../../redux/slices/curriculum/selectors";

import SortSubjects from "../../../../shared/components/settings/curriculums/SortSubjects";
import DeleteSubject from "../../../../shared/components/settings/curriculums/DeleteSubject";
import useFetchSchoolCurriculum from "../../../../shared/api/school/useFetchSchoolCurriculum";
import useFetchGeneralCurriculum from "../../../../shared/api/school/useFetchGeneralCurriculum";
import CurriculumTable from "../../../../shared/components/settings/curriculums/CurriculumTable";
import AddSubjectToCurriculum from "../../../../shared/components/settings/curriculums/AddSubjectToCurriculum";
import CurriculumTableOverview from "../../../../shared/components/settings/curriculums/CurriculumTableOverview";

const JuniorCurriculums = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const selected = query.get("selected");

  const { schoolId } = useParams();
  const navigate = useNavigate();

  const juniorSubjects = useSelector(selectJuniorSubjects);
  const juniorCurriculum = useSelector(selectJuniorCurriculums);

  const [juniorSubjectsLoading, fetchJuniorSubjects] =
    useFetchSchoolCurriculum("junior");
  const [loadingCurriculum] = useFetchGeneralCurriculum("junior");

  function handleAddSubject() {
    navigate(`/${schoolId}/settings/curriculum/junior?action=add`);
  }

  function handleDeleteSubject(sub) {
    navigate(
      `/${schoolId}/settings/curriculum/junior?selected=${sub?.schoolSubjectId}`
    );
  }

  function closeSideModal() {
    navigate(`/${schoolId}/settings/curriculum/junior`);
  }

  function handleSortSubject() {
    navigate(`/${schoolId}/settings/curriculum/junior?action=sort`);
  }

  return (
    <div>
      <div className="page">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Junior Curriculums</h1>
        </div>

        <div className="main_content">
          <CurriculumTableOverview handleAddSubject={handleAddSubject} />
          <CurriculumTable
            data={juniorSubjects}
            handleDeleteSubject={handleDeleteSubject}
            emptyDataButtonTitle={"Sort curriculum"}
            handleEmptyData={handleSortSubject}
          />
        </div>
      </div>

      {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${action ? "block" : ""}`}>
        <AddSubjectToCurriculum
          action={action}
          schoolSubjects={juniorSubjects}
          curriculum={juniorCurriculum}
          curriculumLoading={loadingCurriculum}
          schoolSubjectsLoading={juniorSubjectsLoading}
          closeSideModal={closeSideModal}
          refresh={fetchJuniorSubjects}
          category={"junior"}
        />

        <DeleteSubject
          schoolSubjectId={selected}
          closeSideModal={closeSideModal}
          refresh={fetchJuniorSubjects}
          action={action}
          category={"junior"}
        />

        <SortSubjects
          category={"junior"}
          action={action}
          refresh={fetchJuniorSubjects}
          closeSideModal={closeSideModal}
        />
      </div>
    </div>
  );
};

export default JuniorCurriculums;
