import { useState } from "react";
import { toast } from "react-toastify";

import Loader from "../Loader";
import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import useActivateCalendar from "../../api/calendar/useActivateCalendar";

const ActivateCalendar = ({ closeSideModal, calendar, loadingCalendar }) => {
  const { loading, request } = useActivateCalendar();
  const [text, setText] = useState("");

  async function handleDelete() {
    if (!text || text !== "activate") return toast("Enter correct word");

    const isSuccessful = await request(calendar?.calendarId);

    if (isSuccessful) {
      setText("");
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div className="p-5">
      <div>
        <h2 className="side_page_title">Activate Calendar</h2>
        <p className="text-sm text-independence mb-1">
          Are you sure you want to set calendar as active?
        </p>

        <p className="text-sm text-independence">
          The current active calendar will be deactivated
        </p>
      </div>

      {loadingCalendar ? (
        <Loader loading={loadingCalendar} />
      ) : (
        <div className="mt-8">
          <FormInput
            placeholder={"activate"}
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
            className="mb-2"
          />

          <p className="text-sm text-independence">
            Enter "<span className="text-black font-bold">activate</span>" to
            activate session
          </p>

          <FormButton
            title={"Activate"}
            className={"!bg-primary mt-5"}
            loading={loading}
            onClick={handleDelete}
          />

          <FormButton
            title={"Cancel"}
            className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
            onClick={closeSideModal}
          />
        </div>
      )}
    </div>
  );
};

export default ActivateCalendar;
