import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Pagination from "../../../shared/components/Pagination";
import TableOverview from "../../../shared/components/teachers/TableOverview";
import StudentsTable from "../../../shared/components/users/students/StudentsTable";
import useFetchAllStudents from "../../../shared/api/classes/students/useFetchAllStudents";
import StudentDetails from "../../../shared/components/users/students/StudentDetails";
// import TeacherNavigationBar from "../../../shared/components/teachers/TeacherNavigationBar";

const Students = () => {
  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studentId = query.get("student");
  const type = query.get("type");

  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Pagination state
  |--------------------------------------------------
  */
  const [page, setPage] = useState(1);

  /**
  |--------------------------------------------------
  | Pagination data
  |--------------------------------------------------
  */
  const [pagination, setPagination] = useState(null);

  /**
  |--------------------------------------------------
  | Api hook to get all teachers from server
  |--------------------------------------------------
  */
  const [loading, teachers] = useFetchAllStudents(page, setPagination);

  /**
  |--------------------------------------------------
  | function to close side modal
  |--------------------------------------------------
  */
  function closeSideModal() {
    navigate(`/users/students`);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">Students</h1>

      {/* <TeacherNavigationBar active={1} /> */}

      <div className="main_content">
        <TableOverview />

        <StudentsTable
          data={teachers}
          loading={loading}
          studentId={studentId}
        />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      {studentId ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${studentId ? "block" : ""}`}>
        <StudentDetails
          studentId={studentId}
          closeSideModal={closeSideModal}
          type={type}
        />
      </div>
    </div>
  );
};

export default Students;
