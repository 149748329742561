import { toast } from "react-toastify";

export function getTotalStudents(params) {
  let maleStudents = params?.maleStudents || 0;
  let femaleStudents = params?.femaleStudents || 0;

  return maleStudents + femaleStudents;
}

export function getDayStringFromDate(date) {
  const weekday = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const dayIndex = new Date(date).getDay();

  return weekday[dayIndex];
}

export const formatToStringDate = (date, hideDay) => {
  if (!date) {
    return "";
  }

  let datetime = "";
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentdate = new Date(date);

  if (hideDay) {
    datetime = `${currentdate.getDate()} ${
      months[currentdate.getMonth()]
    } ${currentdate.getFullYear()}`;
  } else {
    datetime = `${days[currentdate.getDay()]}, ${
      months[currentdate.getMonth()]
    } ${currentdate.getDate()} ${currentdate.getFullYear()}`;
  }

  return datetime;
};

export function formatDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  const day = d.getDate();

  month = month?.toString()?.length === 1 ? `0${month}` : month;

  return `${year}-${month}-${day}`;
}

export function formatDateTwo(date, separator = "-") {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();

  return `${day}${separator}${month}${separator}${year}`;
}

export function getAverage(obtainedScore, obtainableScore) {
  if (!obtainableScore || !obtainedScore) return "0";

  let grade = (obtainedScore / obtainableScore) * 100;
  if (grade) return `${grade?.toFixed(0)}`;

  return `0`;
}

export function getGrade(score) {
  if (score >= 75) return "A1";
  if (score >= 70) return "B2";
  if (score >= 65) return "B3";
  if (score >= 60) return "C4";
  if (score >= 55) return "C5";
  if (score >= 50) return "C6";
  if (score >= 45) return "D7";
  if (score >= 40) return "E8";
  if (score < 40) return "F9";
}

export function getRemark(score) {
  if (score >= 75) return "Excellent";
  if (score >= 70) return "Very Good";
  if (score >= 65) return "Good";
  if (score >= 60) return "Credit";
  if (score >= 55) return "Credit";
  if (score >= 50) return "Credit";
  if (score >= 45) return "Pass";
  if (score >= 40) return "Pass";
  if (score < 40) return "Fail";
}

export function getStudentClassStatus(classHistory, type = "end") {
  if (!classHistory) return "";

  let status = "";
  switch (type) {
    case "start": {
      if (classHistory?.startedSessionAs === "entry") status = "New Entry";

      if (classHistory?.startedSessionAs === "retained")
        status = "Retained in this class";

      if (classHistory?.startedSessionAs === "promoted")
        status = "Promoted to this class";

      break;
    }

    case "end": {
      if (classHistory?.endedSessionAs === "graduated") status = "Graduated";

      if (classHistory?.endedSessionAs === "retained")
        status = "Retained in this class";

      if (classHistory?.endedSessionAs === "promoted")
        status = "Promoted to next class";

      if (classHistory?.endedSessionAs === "left") status = "Student left";

      break;
    }

    default:
      break;
  }

  return status;
}

export function getTotalForScorecard(params) {
  let ca1 = params?.continuousAssessmentOneScore || 0;
  let ca2 = params?.continuousAssessmentTwoScore || 0;
  let exam = params?.examScore || 0;

  return ca1 + ca2 + exam;
}

export function getScoreObtained(arr) {
  return arr?.reduce((acc, val) => {
    return acc + Number(val?.totalScore);
  }, 0);
}

export const handleToast = (message, type = "success") => {
  if (type === "success") {
    toast.success(message);
  }

  if (type === "error") {
    toast.error(message);
  }

  if (type === "warning") {
    toast.warning(message);
  }

  if (type === "info") {
    toast.info(message);
  }
};

export const formatCurrency = (num) => {
  if (!num) {
    return 0;
  }

  return Number(num)
    ?.toFixed(0)
    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
