import React, { useEffect, useState } from "react";

import FormInput from "../../form/FormInput";
import NIGERIA_STATES from "../../../constant/nigeriaStates";
import SelectInput from "../../form/SelectInput";

export default function BillingAddress({ billingDetails }) {
  // const { request, loading } = useUpdateBillingSettings();
  const [details, setDetails] = useState(null);
  const [lgas, setLgas] = useState([]);

  useEffect(() => {
    setDetails({
      addressLine1: billingDetails?.addressLine1 || "",
      addressLine2: billingDetails?.addressLine2 || "",
      state: billingDetails?.state || "",
      city: billingDetails?.city || "",
      lga: billingDetails?.lga || "",
      country: billingDetails?.country || "",
      zipCode: billingDetails?.zipCode || "",
      name: billingDetails?.name || "",
      phone: billingDetails?.phone || "",
    });
  }, [billingDetails]);

  useEffect(() => {
    if (details?.state) {
      const [filtered] = Object.values(NIGERIA_STATES)?.filter(
        (el) => el?.name?.toLowerCase() === details.state?.toLowerCase()
      );
      setLgas(filtered?.locals);
    }
  }, [details?.state]);

  // async function updateSettings() {
  //   if (!details?.addressLine1) return handleToast("Address line 1 is invalid");
  //   if (!details?.country) return handleToast("Country is invalid");
  //   if (!details?.state) return handleToast("State is invalid");
  //   if (!details?.lga) return handleToast("LGA is invalid");
  //   if (!details?.phone || details?.phone?.length < 11)
  //     return handleToast("Phone is invalid");
  //   if (!details?.name || details?.name?.length < 5)
  //     return handleToast("Name is invalid");

  //   await request(billingDetails?.billingDetailsId, details);
  // }

  return (
    <div className="table_wrapper">
      {/**
        |--------------------------------------------------
        | Section header
        |--------------------------------------------------
        */}
      <div className="px-3">
        <h4 className="text-yankee_blue font-bold text-lg capitalize">
          Billing Address
        </h4>

        <p className="text-sm text-greyX11 mb-5">
          Please update your billing address.
        </p>

        {/**
        |--------------------------------------------------
        | Section form
        |--------------------------------------------------
        */}
        <div className="md:grid md:grid-cols-2 gap-4">
          {/**
          |--------------------------------------------------
          | Name
          |--------------------------------------------------
          */}
          <FormInput
            label={"Name"}
            placeholder={"Name"}
            value={details?.name || ""}
            onChange={(e) =>
              setDetails({ ...details, name: e.currentTarget.value })
            }
            className="mb-0 flex-1"
            disabled
          />

          {/**
          |--------------------------------------------------
          | Phone
          |--------------------------------------------------
          */}
          <FormInput
            label={"Phone Number"}
            placeholder={"Phone Number"}
            value={details?.phone || ""}
            onChange={(e) =>
              setDetails({ ...details, phone: e.currentTarget.value })
            }
            className="mb-0 flex-1"
            disabled
          />
          {/**
          |--------------------------------------------------
          | Address line 1
          |--------------------------------------------------
          */}
          <FormInput
            label={"Address Line 1"}
            placeholder={"Address line 1"}
            value={details?.addressLine1 || ""}
            onChange={(e) =>
              setDetails({ ...details, addressLine1: e.currentTarget.value })
            }
            className="mb-0 flex-1"
            disabled
          />

          {/**
          |--------------------------------------------------
          | Address line 2
          |--------------------------------------------------
          */}
          <FormInput
            label={"Address Line 2"}
            placeholder={"Address line 2"}
            value={details?.addressLine2 || ""}
            onChange={(e) =>
              setDetails({ ...details, addressLine2: e.currentTarget.value })
            }
            className="mb-0 flex-1"
            disabled
          />

          {/**
            |--------------------------------------------------
            | Country
            |--------------------------------------------------
            */}
          <FormInput
            label={"Country"}
            disabled={true}
            className="mb-0 flex-1"
            placeholder={"Country"}
            value={details?.country || ""}
            onChange={(e) =>
              setDetails({ ...details, country: e.currentTarget.value })
            }
          />

          {/**
          |--------------------------------------------------
          | State
          |--------------------------------------------------
          */}
          <SelectInput
            label={"State"}
            placeholder="state"
            handleSelect={(e) => {
              setDetails({
                ...details,
                state: e.currentTarget.value,
                lga: "",
              });
            }}
            options={Object.values(NIGERIA_STATES)}
            className="mb-0 flex-1"
            value={details?.state || ""}
            disabled
          />

          {/**
          |--------------------------------------------------
          | City
          |--------------------------------------------------
          */}
          <SelectInput
            label={"LGA"}
            placeholder="Select lga"
            handleSelect={(e) => {
              setDetails({
                ...details,
                lga: e.currentTarget.value,
              });
            }}
            options={lgas}
            className="mb-0 flex-1"
            value={details?.lga || ""}
            disabled
          />

          {/**
          |--------------------------------------------------
          | Zip code
          |--------------------------------------------------
          */}
          <FormInput
            label={"Zip Code"}
            placeholder={"Zip Code"}
            value={details?.zipCode || ""}
            onChange={(e) =>
              setDetails({ ...details, zipCode: e.currentTarget.value })
            }
            className="mb-0 flex-1"
            disabled
          />
        </div>

        <div className="md:grid md:grid-cols-2 gap-4 mt-5">
          {/**
          |--------------------------------------------------
          | update button
          |--------------------------------------------------
          */}
          {/* <FormButton
            title={"Update"}
            onClick={updateSettings}
            loading={loading}
          /> */}

          {/**
          |--------------------------------------------------
          | Empty div for 2nd grid col
          |--------------------------------------------------
          */}
          {/* <div /> */}
        </div>
      </div>
    </div>
  );
}
