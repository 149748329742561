import { useCallback, useEffect, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchSubjectById(category, subjectId) {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(null);

  const request = useCallback(async (_category, id) => {
    try {
      setLoading(true);

      const res = await client.get(`/curriculum/${_category}/${id}`);

      setSubject(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (subjectId && category) request(category, subjectId);
  }, [subjectId, request, category]);

  return { subject, loading, request };
}
