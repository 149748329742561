import { useCallback, useEffect, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchAllSubjects = (category) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const request = useCallback(async (_category) => {
    setLoading(true);

    try {
      const { data } = await client.get(`/curriculum/${_category}`);

      setData(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (category) request(category);
  }, [request, category]);

  return { loading, data, request };
};

export default useFetchAllSubjects;
