import { useCallback, useEffect, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchAllStudents = (page, setPagination) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setData(null);
    setPagination(null);

    try {
      const { data } = await client.get(`/student/all?page=${page}`);

      setData(data?.data);
      if (data?.pagination) setPagination(data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [page, setPagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [loading, data];
};

export default useFetchAllStudents;
