import { toast } from "react-toastify";
import React, { useState } from "react";

import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useCreateAcademicSession from "../../../api/curriculum/academicSessions/useCreateAcademicSession";

export default function NewAcademicSession({ closeSideModal }) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useCreateAcademicSession();

  async function createSession() {
    if (!payload?.session) return toast("Session year is required");
    if (!payload?.nextSession) return toast("Next session is required");

    const isSuccess = await request(payload);

    if (isSuccess) {
      setPayload({});
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Create New Academic Session</h3>
      </div>

      <div className="border-t pt-5">
        <FormInput
          label={"Session Year"}
          placeholder={"YYYY-YYYY"}
          required
          onChange={(e) =>
            setPayload({ ...payload, session: e.currentTarget.value })
          }
        />

        <FormInput
          label={"Next Session Year"}
          placeholder={"YYYY-YYYY"}
          required
          onChange={(e) =>
            setPayload({ ...payload, nextSession: e.currentTarget.value })
          }
        />
      </div>

      <div className="mt-10">
        <FormButton
          title={"Add Session"}
          onClick={createSession}
          loading={loading}
        />

        <FormButton
          title={"Cancel"}
          onClick={closeSideModal}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
        />
      </div>
    </div>
  );
}
