import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Pagination from "../../../shared/components/Pagination";
import TableOverview from "../../../shared/components/schools/TableOverview";
import useFetchSchoolOwners from "../../../shared/api/schoolOwners/useFetchSchoolOwners";
import SchoolOwnersTable from "../../../shared/components/users/schoolOwners/SchoolOwnersTable";
import SchoolOwnerDetails from "../../../shared/components/users/schoolOwners/SchoolOwnerDetails";

const SchoolsOwners = () => {
  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const ownerId = query.get("selected");

  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Pagination state
  |--------------------------------------------------
  */
  const [page, setPage] = useState(1);

  /**
  |--------------------------------------------------
  | Pagination data
  |--------------------------------------------------
  */
  const [pagination, setPagination] = useState(null);

  /**
  |--------------------------------------------------
  | Api hook to get all schoolOwners from server
  |--------------------------------------------------
  */
  const [loading, schoolOwners] = useFetchSchoolOwners(page, setPagination);

  /**
  |--------------------------------------------------
  | function to close side modal
  |--------------------------------------------------
  */
  function closeSideModal() {
    navigate(`/users/school-owners`);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">School Owners</h1>

      {/* <TeacherNavigationBar active={1} /> */}

      <div className={`main_content ${ownerId ? "" : "xl:w-fit"}`}>
        <TableOverview />

        <SchoolOwnersTable
          data={schoolOwners}
          loading={loading}
          ownerId={ownerId}
        />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      {ownerId ? (
        <>
          <div className="page_dark_overlay" onClick={closeSideModal} />

          <div className={`page_right_bar !p-0 ${ownerId ? "block" : ""}`}>
            <SchoolOwnerDetails
              ownerId={ownerId}
              closeSideModal={closeSideModal}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SchoolsOwners;
