import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";

import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";

const SubjectTable = ({
  data,
  loading,
  category,
  selected,
  handleEditSubject,
  handleDeleteSubject,
}) => {
  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-7 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Subject Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">
              {category === "junior" ? "Group" : "Department"}
            </h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Category</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Date Created</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Action</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No subject records found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-7 border-b table_row hover:active_table_row ${
              selected === item?.subjectId ? "active_table_row" : ""
            }`}
            key={i}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.name}
              </p>
            </div>

            <div className="border-r p-3 col-span-2">
              <p className="text-independence text-sm capitalize">
                {item?.group || item?.department}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.category}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
            </div>

            <div className="px-3 flex items-center gap-2">
              <button
                onClick={() => handleEditSubject(item)}
                className="text-independence h-8 w-8 rounded-full flex items-center justify-center cursor-pointer hover:bg-primary hover:text-white"
              >
                <FaEdit className="text-base" />
              </button>

              <button
                onClick={() => handleDeleteSubject(item)}
                className="text-independence h-8 w-8 rounded-full flex items-center justify-center cursor-pointer hover:bg-coral_red hover:text-white"
              >
                <MdOutlineDeleteOutline className="text-lg" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubjectTable;
