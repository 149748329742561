import { useCallback, useState } from "react";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";
import { handleToast } from "../../../utils/functions";

export default function useActivateAcademicSessionById() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);

      const res = await client.patch(`/calendar/academic-sessions/${id}`);

      setLoading(false);
      handleToast(res.data?.message);

      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { loading, request };
}
