import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import FormInput from "../../../../shared/components/form/FormInput";
import FormButton from "../../../../shared/components/form/FormButton";
import SelectInput from "../../../../shared/components/form/SelectInput";
import useCreateEvent from "../../../../shared/api/calendar/events/useCreateEvent";

const NewEvent = () => {
  const { schoolId, date } = useParams();
  const calendar = useSelector(selectCalendar);

  const [createEventRequest, loading] = useCreateEvent();
  const [payload, setPayload] = useState({
    startDate: date,
    endDate: date,
  });

  async function createEvent() {
    if (!calendar) return toast("Calendar is required");
    if (!payload?.title) return toast("Title is required");
    if (!payload?.startDate) return toast("Start date is required");
    if (!payload?.endDate) return toast("End Date is required");

    await createEventRequest({
      ...payload,
      calendarId: calendar?.calendarId,
      schoolId,
      category: "school",
    });
  }

  return (
    <div>
      <div className="page">
        <div className="flex items-center my-5">
          <h1 className="page_title flex-1">New Event</h1>
        </div>

        <div className="main_content px-5">
          <h2 className="text-xs sm:text-base font-semibold mb-5">
            Event Details
          </h2>

          <div className="grid gap-3 s:gap-5 s:grid-cols-2">
            <FormInput
              label={"Event Title"}
              placeholder="Enter Title"
              value={payload?.title || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  title: e.currentTarget.value,
                })
              }
              className="mb-0"
            />

            <SelectInput
              placeholder="Select event type"
              label={"Is event an holiday"}
              required
              value={payload?.isHoliday || ""}
              handleSelect={(e) =>
                setPayload({
                  ...payload,
                  isHoliday: e.currentTarget.value,
                })
              }
              options={[
                { name: "Yes", value: true },
                { name: "No", value: false },
              ]}
              className="mb-0"
            />

            <FormInput
              label={"Start Date"}
              placeholder="Choose Beginning of vacation"
              type="date"
              value={payload?.startDate || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  startDate: e.currentTarget.value,
                })
              }
              className="mb-0"
            />

            <FormInput
              label={"End date"}
              placeholder="Choose end of vacation"
              type="date"
              value={payload?.endDate || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  endDate: e.currentTarget.value,
                })
              }
              className="mb-0"
            />

            <FormInput
              label={"Start Time"}
              placeholder="Choose time"
              type="time"
              value={payload?.startTime || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  startTime: e.currentTarget.value,
                })
              }
              className="mb-0"
            />

            <FormInput
              label={"End Time"}
              placeholder="Choose time"
              type="time"
              value={payload?.endTime || ""}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  endTime: e.currentTarget.value,
                })
              }
              className="mb-0"
            />
          </div>

          <div className="max-w-xs mx-auto my-10">
            <FormButton
              title={"Create Event"}
              onClick={createEvent}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEvent;
