import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchSchoolOwners = (page, setPagination) => {
  const [loading, setLoading] = useState(false);

  const [teachers, setTeachers] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/user/all/schoolowner?page=${page}`);

      setTeachers(data?.data);
      if (data?.pagination && setPagination) setPagination(data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [page, setPagination]);

  useEffect(() => {
    request();
  }, [request]);

  return [loading, teachers];
};

export default useFetchSchoolOwners;
