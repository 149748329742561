import DetailRow from "../general/DetailRow";
import Loader from "../Loader";
import IMAGES from "../../constant/images";
import useFetchTeacherBasicInfo from "../../api/teachers/useFetchTeacherBasicInfo";

const TeacherDetails = ({ teacherId, closeSideModal }) => {
  const [details, loading] = useFetchTeacherBasicInfo(teacherId);

  if (!teacherId) return null;
  if (loading) return <Loader loading={loading} />;

  return (
    <div className="h-full flex flex-col">
      <div className="w-full h-72">
        <img
          src={details?.profileImage || IMAGES.studentAvatar}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg capitalize">
            {details?.lastName || ""} {details?.firstName || ""}
          </h3>

          <DetailRow label={"Email address"} value={details?.email || "N/A"} />
          <DetailRow label={"Contact"} value={details?.phone || "N/A"} />
          <DetailRow label={"Address"} value={details?.address || "N/A"} />
          <DetailRow label={"Religion"} value={details?.religion || "N/A"} />

          <DetailRow label={"Gender"} value={details?.gender || "N/A"} />

          <DetailRow label={"Ethnicity"} value={details?.ethnicity || "N/A"} />

          <DetailRow
            label={"Marital status"}
            value={details?.maritalStatus || "N/A"}
          />

          <DetailRow
            label={"Nationality"}
            value={details?.nationality || "N/A"}
          />

          <DetailRow
            label={"State of origin"}
            value={details?.stateOfOrigin || "N/A"}
          />

          <DetailRow label={"L.G.A"} value={"N/A"} />
        </div>

        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg">
            Contact Information
          </h3>

          <DetailRow value={details?.email || "N/A"} label={"Email"} />

          <DetailRow value={details?.twitter || "N/A"} label={"Twitter"} />

          <DetailRow value={details?.facebook || "N/A"} label={"Facebook"} />

          <DetailRow value={details?.linkedIn || "N/A"} label={"LinkedIn"} />

          <DetailRow value={details?.instagram || "N/A"} label={"Instagram"} />

          <DetailRow
            value={details?.emergencyContact1 || "N/A"}
            label={"Emergency contact"}
          />

          <DetailRow
            value={details?.emergencyContact2 || "N/A"}
            label={"Emergency contact 2"}
          />
        </div>

        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg">
            Medical Information
          </h3>

          <div className="grid grid-cols-2">
            <DetailRow label={"Weight"} value={details?.weight || "N/A"} />
            <DetailRow label={"Height"} value={details?.height || "N/A"} />
          </div>

          <div className="grid grid-cols-2">
            <DetailRow
              label={"Blood group"}
              value={details?.bloodType || "N/A"}
            />
            <DetailRow label={"Genotype"} value={details?.genoType || "N/A"} />
          </div>
        </div>

        <div className="py-3 px-3">
          <h3 className="font-bold text-yankee_blue mb-3 text-lg">
            Academic Information
          </h3>

          <h3 className="text-black-text font-semibold my-1">Primary school</h3>
          <div className="mb-10">
            <DetailRow
              label={"School name"}
              value={details?.primarySchoolName || "N/A"}
            />

            <DetailRow
              label={"Start year"}
              value={details?.primarySchoolStartYear || "N/A"}
            />

            <DetailRow
              label={"End year"}
              value={details?.primarySchoolEndYear || "N/A"}
            />
          </div>

          <h3 className="text-black-text font-semibold my-1">
            Secondary school
          </h3>
          <div className="mb-10">
            <DetailRow
              label={"School name"}
              value={details?.secondarySchoolName || "N/A"}
            />

            <DetailRow
              label={"Start year"}
              value={details?.secondarySchoolStartYear || "N/A"}
            />

            <DetailRow
              label={"End year"}
              value={details?.secondarySchoolEndYear || "N/A"}
            />

            <DetailRow
              label={"Department"}
              value={details?.secondarySchoolDepartment || "N/A"}
            />
          </div>

          <h3 className="text-black-text font-semibold my-1">
            Higher institution
          </h3>
          <div className="mb-10">
            <DetailRow
              label={"School name"}
              value={details?.tertiarySchoolName || "N/A"}
            />

            <DetailRow
              label={"Course"}
              value={details?.tertiarySchoolCourse || "N/A"}
            />

            <DetailRow
              label={"Faculty"}
              value={details?.tertiarySchoolFaculty || "N/A"}
            />

            <DetailRow
              label={"Start year"}
              value={details?.tertiarySchoolStartYear || "N/A"}
            />

            <DetailRow
              label={"End year"}
              value={details?.tertiarySchoolEndYear || "N/A"}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="py-3 px-3 mt-auto">
          <button
            className="bg-transparent text-coral_red h-10 w-full rounded-md text-sm font-semibold"
            onClick={closeSideModal}
          >
            Back to list
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeacherDetails;
