import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Pagination from "../../../shared/components/Pagination";
import useFetchTeachers from "../../../shared/api/teachers/useFetchTeachers";
import TableOverview from "../../../shared/components/teachers/TableOverview";
import TeachersTable from "../../../shared/components/teachers/TeachersTable";
import TeacherDetails from "../../../shared/components/teachers/TeacherDetails";
// import TeacherNavigationBar from "../../../shared/components/teachers/TeacherNavigationBar";

const Teachers = () => {
  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const teacherId = query.get("selected");

  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Pagination state
  |--------------------------------------------------
  */
  const [page, setPage] = useState(1);

  /**
  |--------------------------------------------------
  | Pagination data
  |--------------------------------------------------
  */
  const [pagination, setPagination] = useState(null);

  /**
  |--------------------------------------------------
  | Api hook to get all teachers from server
  |--------------------------------------------------
  */
  const [loading, teachers] = useFetchTeachers(page, setPagination);

  /**
  |--------------------------------------------------
  | function to close side modal
  |--------------------------------------------------
  */
  function closeSideModal() {
    navigate(`/users/teachers`);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">Teachers</h1>

      {/* <TeacherNavigationBar active={1} /> */}

      <div className="main_content">
        <TableOverview />

        <TeachersTable
          data={teachers}
          loading={loading}
          teacherId={teacherId}
        />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      {teacherId ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${teacherId ? "block" : ""}`}>
        <TeacherDetails teacherId={teacherId} closeSideModal={closeSideModal} />
      </div>
    </div>
  );
};
export default Teachers;
