import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchEventById(eventId) {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);

      const { data } = await client.get(`/calendar-event/${id}`);
      setEvent(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (eventId) request(eventId);
  }, [eventId, request]);

  return { event, loading, request };
}
