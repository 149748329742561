import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchCalendarEvents(calendarId) {
  const { schoolId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(
    async (_calendarId) => {
      setLoading(true);
      try {
        const res = await client.get(
          `/calendar-event/calendar/${_calendarId}`,
          {
            params: {
              school: schoolId || undefined,
            },
          }
        );

        setData(res.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [schoolId]
  );

  useEffect(() => {
    if (calendarId) {
      request(calendarId);
    }
  }, [request, calendarId]);

  return [data, loading];
}
