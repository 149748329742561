import React, { useEffect, useState } from "react";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useEditInvoice from "../../../api/payment/useEditInvoice";
import { formatDate, handleToast } from "../../../utils/functions";
import useFetchInvoiceById from "../../../api/payment/useFetchInvoiceById";

export default function EditInvoice({
  closeSideModal,
  schools,
  selected,
  plans,
}) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useEditInvoice();
  const { loading: loadingInvoice, data: invoice } =
    useFetchInvoiceById(selected);

  useEffect(() => {
    setPayload({
      description: invoice?.description,
      status: invoice?.status,
      quantity: invoice?.quantity,
      discount: invoice?.discount,
      paymentDueDate: formatDate(invoice?.paymentDueDate),
      paymentExpiryDate: formatDate(invoice?.paymentExpiryDate),
      schoolId: invoice?.schoolId,
      planId: invoice?.planId,
    });
  }, [invoice]);

  async function editInvoice(e) {
    e.preventDefault();

    if (!payload?.description)
      return handleToast("Description is required", "warning");
    if (!payload?.status) return handleToast("Status is required", "warning");
    if (!payload?.quantity)
      return handleToast("Quantity is required", "warning");
    if (!payload?.paymentDueDate)
      return handleToast("Payment due date is required", "warning");
    if (!payload?.paymentExpiryDate)
      return handleToast("Payment expiry date is required", "warning");
    if (!payload?.schoolId) return handleToast("School is required", "warning");
    if (!payload?.planId) return handleToast("Plan is required", "warning");

    payload.currency = "NGN";

    const isSuccess = await request(selected, payload);

    if (isSuccess) {
      setPayload({});
      closeSideModal({ refresh: true });
    }
  }

  if (loadingInvoice) {
    return <Loader loading={loadingInvoice} />;
  }

  return (
    <div className="px-3">
      <h3 className="side_page_title">Create new invoice</h3>

      <form onSubmit={editInvoice}>
        <div className="border-t pt-5">
          <FormInput
            required
            label={"Invoice Desription"}
            placeholder={"Enter description name"}
            onChange={(e) =>
              setPayload({ ...payload, description: e.currentTarget.value })
            }
            className="mb-3"
            value={payload?.description || ""}
          />

          {/* <SelectInput
          label={"Invoice Currency"}
          placeholder="Choose a currency invoice"
          handleSelect={(e) =>
            setPayload({ ...payload, group: e.currentTarget.value })
          }
          options={[{ name: "Naira", value: "NGN" }]}
          className="mb-3"
        /> */}

          <SelectInput
            label={"Invoice Status"}
            placeholder="Choose invoice status"
            handleSelect={(e) =>
              setPayload({ ...payload, status: e.currentTarget.value })
            }
            options={[
              { name: "Pending", value: "pending" },
              { name: "Paid", value: "paid" },
              { name: "Overdue", value: "overdue" },
            ]}
            className="mb-3"
            value={payload?.status || ""}
          />

          <FormInput
            required
            type="date"
            label={"Payment Due Date"}
            onChange={(e) =>
              setPayload({ ...payload, paymentDueDate: e.currentTarget.value })
            }
            className="mb-3"
            value={payload?.paymentDueDate || ""}
          />

          <FormInput
            required
            type="date"
            label={"Payment Expiry Date"}
            onChange={(e) =>
              setPayload({
                ...payload,
                paymentExpiryDate: e.currentTarget.value,
              })
            }
            className="mb-3"
            value={payload?.paymentExpiryDate || ""}
          />

          <FormInput
            required
            label={"Quantity"}
            placeholder={"Enter invoice quantity"}
            onChange={(e) =>
              setPayload({ ...payload, quantity: e.currentTarget.value })
            }
            className="mb-3"
            value={payload?.quantity || ""}
          />

          <FormInput
            required
            label={"Discount (%)"}
            placeholder={"Enter invoice discount"}
            onChange={(e) =>
              setPayload({
                ...payload,
                discount: Number(e.currentTarget.value),
              })
            }
            className="mb-3"
            value={payload?.discount || 0}
          />

          <SelectInput
            label={"School"}
            options={schools}
            className="mb-3"
            optionValueLabel="schoolId"
            placeholder="Select a school"
            handleSelect={(e) =>
              setPayload({ ...payload, schoolId: e.currentTarget.value })
            }
            value={payload?.schoolId || ""}
          />

          <SelectInput
            label={"Plan"}
            options={plans}
            className="mb-3"
            optionValueLabel="planId"
            placeholder="Select a plan"
            handleSelect={(e) =>
              setPayload({ ...payload, planId: e.currentTarget.value })
            }
            getKeyLabel={(el) => `${el?.name} - ${el?.state}`}
            value={payload?.planId || ""}
          />
        </div>

        <div className="mt-10">
          <FormButton title={"Update Invoice"} loading={loading} />

          <FormButton
            title={"Cancel"}
            onClick={closeSideModal}
            className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
          />
        </div>
      </form>
    </div>
  );
}
