import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useCreateInvoice() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);
      const response = await client.post(`/payment/invoices/new`, payload);

      setData(response.data?.data);
      setLoading(false);
      handleToast(response.data?.message);

      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading, data };
}
