import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import SelectInput from "../form/SelectInput";
import useEditCalendar from "../../api/calendar/useEditCalendar";

export default function EditCalendar({ calendar, closeSideModal }) {
  const [payload, setPayload] = useState({});

  const { loading, request } = useEditCalendar();

  async function editCalendar() {
    if (!payload?.session) return toast("Session is required");
    if (!payload?.term) return toast("Term is required");
    if (!payload?.resumptionDate) return toast("Resumption Date is required");
    if (!payload?.closingDate) return toast("Closing Date is required");
    if (!payload?.vacationStartDate)
      return toast("Vacation Start Date is required");
    if (!payload?.vacationEndDate)
      return toast("Vacation End Date is required");
    if (!payload?.academicDays) return toast("Academic Days is required");
    if (!payload?.vacationDays) return toast("Vacation Days is required");
    if (!payload?.nextResumptionDate)
      return toast("Next term resumption date is required");

    const isSuccess = await request(payload, calendar?.calendarId);
    if (isSuccess) {
      setPayload({});
      closeSideModal();
    }
  }

  useEffect(() => {
    setPayload({
      session: calendar?.session,
      term: calendar?.term,
      resumptionDate: calendar?.resumptionDate?.split("T")?.[0],
      closingDate: calendar?.closingDate?.split("T")?.[0],
      academicDays: calendar?.academicDays,
      vacationStartDate: calendar?.vacationStartDate?.split("T")?.[0],
      vacationEndDate: calendar?.vacationEndDate?.split("T")?.[0],
      vacationDays: calendar?.vacationDays,
      state: calendar?.state,
      nextResumptionDate: calendar?.nextResumptionDate,
    });
  }, [calendar]);

  return (
    <div className="py-5 px-3">
      <div className="mb-5">
        <h3 className="side_page_title">Edit Calendar</h3>
      </div>

      {/**
        |--------------------------------------------------
        | Academic Calendar
        |--------------------------------------------------
        */}
      <div className="mb-5 pb-5 border-b">
        <h2 className="text-sm s:text-sm font-semibold mb-2">
          Academic Details
        </h2>

        <FormInput
          disabled={true}
          placeholder="yyyy-yyyy"
          label={"Academic Session"}
          value={payload?.session || ""}
          onChange={(e) =>
            setPayload({ ...payload, session: e.currentTarget.value })
          }
          className="mb-2"
        />

        <SelectInput
          placeholder="Select Term"
          label={"Term"}
          required
          value={payload?.term || ""}
          handleSelect={(e) =>
            setPayload({
              ...payload,
              term: e.currentTarget.value,
            })
          }
          options={[
            { name: "First Term", value: "first" },
            { name: "Second Term", value: "second" },
            { name: "Third Term", value: "third" },
          ]}
          className="mb-2"
        />

        <FormInput
          label={"Resumption Date"}
          placeholder="Choose resumption date"
          type="date"
          value={payload?.resumptionDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              resumptionDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Closing Date"}
          placeholder="Choose Closing Date"
          type="date"
          value={payload?.closingDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              closingDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Total Days"}
          placeholder="Enter total days"
          value={payload?.academicDays || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              academicDays: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Next Term Resumption Date"}
          placeholder="Choose next term resumption date"
          type="date"
          value={payload?.nextResumptionDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              nextResumptionDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />
      </div>

      {/**
        |--------------------------------------------------
        | Vacation
        |--------------------------------------------------
        */}
      <div className="mb-10">
        <h2 className="text-sm s:text-sm font-semibold mb-2">
          Vacation Details
        </h2>

        <FormInput
          label={"Start Date"}
          placeholder="Choose Beginning of vacation"
          type="date"
          value={payload?.vacationStartDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              vacationStartDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />
        <FormInput
          label={"End date"}
          placeholder="Choose end of vacation"
          type="date"
          value={payload?.vacationEndDate || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              vacationEndDate: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Total Days"}
          placeholder="Enter Total days"
          value={payload?.vacationDays || ""}
          onChange={(e) =>
            setPayload({
              ...payload,
              vacationDays: e.currentTarget.value,
            })
          }
          className="mb-2"
        />
      </div>

      <FormButton
        title={"Update Calendar"}
        onClick={editCalendar}
        loading={loading}
      />

      <FormButton
        title={"Cancel"}
        className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
        onClick={closeSideModal}
      />
    </div>
  );
}
