import React from "react";

export default function CalendarDetails({
  calendar,
  handleEditCalendar,
  handleAddEvent,
  handleActivate,
}) {
  function convertDaysToWeeksAndDays(num) {
    if (!num) return;

    if (num > 5) {
      let weeks = Math.floor(num / 5);
      let days = num % 5;

      return `(${weeks} weeks and ${days} days)`;
    } else {
      return "";
    }
  }

  return (
    <div className="table_wrapper">
      {/**
        |--------------------------------------------------
        | First row - Header
        |--------------------------------------------------
        */}
      <div className="px-3 border-b pb-3 flex items-center justify-between">
        <h4 className="text-yankee_blue font-bold capitalize">
          {`${calendar?.state} state - ${calendar?.term} term calendar, ${calendar?.session}`}
        </h4>

        {calendar?.isCurrent ? (
          <button className="bg-primary/20 text-primary border-none text-sm py-1 px-3 s:px-4">
            Active
          </button>
        ) : (
          <button
            onClick={handleActivate}
            className="bg-primary/20 text-primary border-none text-sm py-1 px-3 s:px-4"
          >
            Set as active
          </button>
        )}
      </div>

      {/**
        |--------------------------------------------------
        | Second row Academic duration
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
        |--------------------------------------------------
        | Date
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm">
            {`${new Date(
              calendar?.resumptionDate
            )?.toDateString()} - ${new Date(
              calendar?.closingDate
            )?.toDateString()}`}
          </h4>

          <p className="text-sm text-greyX11">Academic Duration</p>
        </div>

        {/**
        |--------------------------------------------------
        | Days
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {`${calendar?.academicDays} days ${convertDaysToWeeksAndDays(
              calendar?.academicDays
            )}`}
          </h4>

          <p className="text-sm text-greyX11">Total Days</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Second row - Vacation
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
            |--------------------------------------------------
            | Date
            |--------------------------------------------------
            */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm">
            {`${new Date(
              calendar?.vacationStartDate
            )?.toDateString()} - ${new Date(
              calendar?.vacationEndDate
            )?.toDateString()}`}
          </h4>

          <p className="text-sm text-greyX11">Vacation</p>
        </div>

        {/**
        |--------------------------------------------------
        | Days
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {`${calendar?.vacationDays} days ${convertDaysToWeeksAndDays(
              calendar?.vacationDays
            )}`}
          </h4>

          <p className="text-sm text-greyX11">Total Days</p>
        </div>

        {/**
        |--------------------------------------------------
        | Next Resumption Date
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {new Date(calendar?.nextResumptionDate)?.toDateString()}
          </h4>

          <p className="text-sm text-greyX11">Next Resumption Date</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Third row - Calendar actions
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-1 pt-5 border-none pb-0">
        <div>
          {/**
            |--------------------------------------------------
            | View invoice
            |--------------------------------------------------
            */}
          <button
            className="text-sm py-1 px-2 text-independence border border-morning_blue w-fit ml-3 rounded-sm hover:border-primary hover:text-primary"
            onClick={handleEditCalendar}
          >
            Edit Calendar
          </button>

          {/**
            |--------------------------------------------------
            | button to upgrade plan
            |--------------------------------------------------
            */}
          <button
            className="text-sm py-1 px-2 text-independence border border-morning_blue w-fit ml-3 rounded-sm hover:border-primary hover:text-primary"
            onClick={handleAddEvent}
          >
            Add Event
          </button>
        </div>
      </div>
    </div>
  );
}
