import { useState } from "react";
import { toast } from "react-toastify";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useDeleteSubjectById from "../../../api/curriculum/subjects/useDeleteSubjectById";

const DeleteSubject = ({
  closeSideModal,
  subject,
  loadingSubject,
  category,
}) => {
  const { loading, request } = useDeleteSubjectById();
  const [card, setCard] = useState("");

  async function handleDeleteScorecard() {
    if (card !== subject?.name) return toast("Enter correct subject name");

    const isSuccessful = await request(category, subject?.subjectId);

    if (isSuccessful) {
      setCard(null);
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div>
      <div>
        <h2 className="side_page_title">Delete Subject</h2>
        <p className="text-sm text-independence">
          Are you sure you want to delete subject? <br /> This action cannot be
          undone
        </p>
      </div>

      {loadingSubject ? (
        <Loader loading={loadingSubject} />
      ) : (
        <div className="mt-8">
          <FormInput
            placeholder={subject?.name}
            value={card}
            onChange={(e) => setCard(e.currentTarget.value)}
            className="mb-2"
          />

          <p className="text-sm text-independence">
            Enter "<span className="text-black font-bold">{subject?.name}</span>
            " to permanently delete subject
          </p>

          <FormButton
            title={"Delete Subject"}
            className={"!bg-coral_red mt-5"}
            loading={loading}
            onClick={handleDeleteScorecard}
          />

          <FormButton
            title={"Cancel"}
            className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
            onClick={closeSideModal}
          />
        </div>
      )}
    </div>
  );
};

export default DeleteSubject;
