import { useCallback, useEffect, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

const useFetchAcademicSessions = (status) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/calendar/academic-sessions`, {
        params: {
          status,
        },
      });

      setData(data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [status]);

  useEffect(() => {
    request();
  }, [request]);

  return { loading, data, request };
};

export default useFetchAcademicSessions;
