import { FiUsers } from "react-icons/fi";

import Loader from "../Loader";
import TableOverviewCard from "../TableOverviewCard";

const TableOverview = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Loader loading={loading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[900px]">
          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Schools"}
            value={0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />
        </div>
      </div>
    </>
  );
};

export default TableOverview;
