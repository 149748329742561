import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useFetchInvoices from "../../../shared/api/payment/useFetchInvoices";
import NewInvoice from "../../../shared/components/payment/invoice/NewInvoice";
import useFetchAllSchools from "../../../shared/api/school/useFetchAllSchools";
import InvoiceTable from "../../../shared/components/payment/invoice/InvoiceTable";
import InvoiceOverview from "../../../shared/components/payment/invoice/InvoiceOverview";
import EditInvoice from "../../../shared/components/payment/invoice/EditInvoice";
import useFetchPlans from "../../../shared/api/payment/useFetchPlans";

export default function Invoices() {
  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const action = query.get("action");
  const selected = query.get("selected");

  /**
  |--------------------------------------------------
  | Api hook to get all subjects
  |--------------------------------------------------
  */
  const { schools } = useFetchAllSchools();
  const { data: plans } = useFetchPlans();
  const { data: invoices, loading, request } = useFetchInvoices();

  const closeSideModal = (param) => {
    if (param?.refresh) request();

    navigate(`/payment/invoices`);
  };

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <h1 className="page_title mb-1">Invoices</h1>

          <button
            className="bg-primary text-white px-3 py-1 rounded-sm"
            onClick={() => {
              navigate(`/payment/invoices?action=new-invoice`);
            }}
          >
            <small className="text-xs">New Invoice</small>
          </button>
        </div>

        <div className="main_content">
          <InvoiceOverview totalInvoices={invoices?.length} />

          <InvoiceTable data={invoices} loading={loading} />
        </div>
      </div>

      {selected ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${selected ? "block" : ""}`}>
        {action === "new-invoice" ? (
          <NewInvoice
            plans={plans}
            schools={schools}
            selected={selected}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {action === "edit" ? (
          <EditInvoice
            plans={plans}
            schools={schools}
            selected={selected}
            closeSideModal={closeSideModal}
          />
        ) : null}
      </div>
    </div>
  );
}
