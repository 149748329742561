import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import InvoicePayment from "../../../../shared/components/settings/billing/InvoicePayment";
import InvoiceBilledTo from "../../../../shared/components/settings/billing/InvoiceBilledTo";
import InvoiceOverview from "../../../../shared/components/settings/billing/InvoiceOverview";
import BillingNavigationBar from "../../../../shared/components/settings/billing/BillingNavigationBar";
import useFetchInvoiceById from "../../../../shared/api/payment/useFetchInvoiceById";

export default function BillingInvoice() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");

  const navigate = useNavigate();
  const { invoiceId, schoolId } = useParams();
  const { data: invoice } = useFetchInvoiceById(invoiceId);

  function handlePayment() {
    navigate(
      `/${schoolId}/settings/billing/invoices/${invoiceId}?action=payment`
    );
  }

  function closeSideModal() {
    navigate(`/${schoolId}/settings/billing/invoices/${invoiceId}`);
  }

  return (
    <div>
      <div className="page">
        {/**
        |--------------------------------------------------
        | Page title
        |--------------------------------------------------
        */}
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Invoice</h1>
        </div>

        {/**
        |--------------------------------------------------
        | Navigation bar
        |--------------------------------------------------
        */}
        <BillingNavigationBar activeIndex={2} />

        {/**
        |--------------------------------------------------
        | Main content
        |--------------------------------------------------
        */}
        <div className="main_content !pb-0 mb-10">
          {/**
            |--------------------------------------------------
            | Invoice Overview
            |--------------------------------------------------
            */}
          <InvoiceOverview invoice={invoice} handlePayment={handlePayment} />
        </div>

        <div className="main_content mb-10">
          {/**
            |--------------------------------------------------
            | Payment  details
            |--------------------------------------------------
            */}
          <InvoicePayment invoice={invoice} />
        </div>

        <div className="main_content">
          {/**
            |--------------------------------------------------
            | Billing address
            |--------------------------------------------------
            */}
          <InvoiceBilledTo invoice={invoice} />
        </div>
      </div>

      {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${action ? "block" : ""}`}>
        {/* {action === "payment" ? (
          <MakePayment invoice={invoice} handleCancel={closeSideModal} />
        ) : null} */}
      </div>
    </div>
  );
}
