import InvitesTable from "../../../../shared/components/teachers/InvitesTable";
import useFetchInvitations from "../../../../shared/api/invitation/useFetchInvitations";
import TeacherNavigationBar from "../../../../shared/components/teachers/TeacherNavigationBar";

const PendingInvites = () => {
  const [invites, loadingInvites, fetchInvites] = useFetchInvitations();

  return (
    <div className="page">
      <h1 className="page_title mb-5">Pending Invites</h1>

      <TeacherNavigationBar active={3} />

      <div className="main_content px-5">
        <InvitesTable
          data={invites}
          loading={loadingInvites}
          fetchInvites={fetchInvites}
        />
      </div>
    </div>
  );
};
export default PendingInvites;
