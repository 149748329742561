import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchPlans(state) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (_state) => {
    try {
      setLoading(true);
      const response = await client.get("/plans", {
        params: { state: _state },
      });

      setData(response.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    request(state);
  }, [request, state]);

  return { request, loading, data };
}
