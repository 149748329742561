import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateTwo, formatToStringDate } from "../../../utils/functions";

export default function BillingOverview({ billingDetails }) {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  return (
    <div className="table_wrapper">
      {/**
        |--------------------------------------------------
        | First row - plan type
        |--------------------------------------------------
        */}
      <div className="table_header">
        {/**
        |--------------------------------------------------
        | Current plan details
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {billingDetails?.plan?.name}
          </h4>

          <p className="text-sm text-greyX11">Current Plan</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Second row - plan billing date info
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
        |--------------------------------------------------
        | User last billed date
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatToStringDate(billingDetails?.invoice?.paymentDate)}
          </h4>

          <p className="text-sm text-greyX11">Last Billed Date</p>
        </div>

        {/**
        |--------------------------------------------------
        | Billing Period
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatDateTwo(billingDetails?.invoice?.paymentDueDate, "/")} -
            {formatDateTwo(billingDetails?.invoice?.paymentExpiryDate, "/")}
          </h4>

          <p className="text-sm text-greyX11">Billing Period</p>
        </div>

        {/**
        |--------------------------------------------------
        | Next billing date
        |--------------------------------------------------
        */}
        <div className="px-3">
          <h4 className="text-yankee_blue font-bold text-sm capitalize">
            {formatToStringDate(billingDetails?.nextPaymentDate)}
          </h4>

          <p className="text-sm text-greyX11">Next Billing Date</p>
        </div>
      </div>

      {/**
        |--------------------------------------------------
        | Third row - plan actions
        |--------------------------------------------------
        */}
      <div className="table_header !grid-cols-3 pt-5">
        {/**
        |--------------------------------------------------
        | View invoice
        |--------------------------------------------------
        */}
        <button
          className="text-sm py-1 px-2 text-independence border border-morning_blue w-fit ml-3"
          onClick={() =>
            navigate(
              `/${schoolId}/settings/billing/invoices/${billingDetails?.invoice?.invoiceId}`
            )
          }
        >
          View Current Invoice
        </button>

        {/**
        |--------------------------------------------------
        | button to upgrade plan
        |--------------------------------------------------
        */}
        <button className="text-sm py-1 px-2 text-independence border border-morning_blue w-fit ml-3">
          Upgrade plan
        </button>

        {/**
        |--------------------------------------------------
        | button to request for custom sales
        |--------------------------------------------------
        */}
        <div className="px-3 flex items-center gap-3">
          <p className="text-sm text-morning_blue">Custom needs?</p>
          <button className="text-sm py-1 px-2 text-independence border border-morning_blue w-fit">
            Contact Sales
          </button>
        </div>
      </div>
    </div>
  );
}
