import { MdOutlineDeleteOutline } from "react-icons/md";
import EmptyTable from "../../EmptyTable";
import Loader from "../../Loader";

const CurriculumTable = ({
  loading,
  handleDeleteSubject,
  data,
  emptyDataButtonTitle,
  handleEmptyData,
}) => {
  return (
    <div className="overflow-auto max-w-[90vw]">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-6 border-b">
          <div className="col-span-3 border-r p-3">
            <h5 className="font-semibold text-sm">Subject</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Group</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Last Update</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm"> </h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No subject record found"}
            onClickMessage={emptyDataButtonTitle}
            onClick={handleEmptyData}
          />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => (
          <div className="grid grid-cols-6 border-b" key={i}>
            <div className="col-span-3 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {card?.name}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">
                {card?.group || card?.department}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {new Date(card?.updatedAt).toDateString()}
              </p>
            </div>

            <div className="p-3" onClick={() => handleDeleteSubject(card)}>
              {/* <p className="text-independence text-sm">Delete</p> */}
              <div className="text-independence h-8 w-8 rounded-full flex items-center justify-center cursor-pointer hover:bg-coral_red hover:text-white">
                <MdOutlineDeleteOutline />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurriculumTable;
