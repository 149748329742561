import { useCallback, useState } from "react";
import client from "../client";
import { useParams } from "react-router-dom";
import handleApiError from "../../hooks/handleApiError";
import { useEffect } from "react";

export default function useFetchSchoolClasses(session) {
  const { schoolId } = useParams();
  const [juniorClasses, setJuniorClasses] = useState(null);
  const [seniorClasses, setSeniorClasses] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (_session) => {
      try {
        setLoading(true);

        const { data } = await client.get(
          `/classes?school=${schoolId}&session=${_session}`
        );

        setJuniorClasses(
          data?.data?.filter((c) => c?.category?.startsWith("JSS"))
        );
        setSeniorClasses(
          data?.data?.filter((c) => c?.category?.startsWith("SSS"))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [schoolId]
  );

  useEffect(() => {
    if (session) request(session);
  }, [request, session]);

  return [juniorClasses, seniorClasses, loading, request];
}
