import { PiBooks } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import { GiBookshelf } from "react-icons/gi";
import { GrScorecard } from "react-icons/gr";
import { MdOutlineCoPresent } from "react-icons/md";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";

import Logo from "./Logo";

const ClassSidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const { schoolId, classId } = useParams();

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  // const routes = [
  //   {
  //     activeIndex: 2,
  //     label: "Dashboard",
  //     icon: <RxDashboard />,
  //     activeIndexLabel: "dashboard",
  //     route: `/${schoolId}/dashboard`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Teachers",
  //     icon: <FiUsers />,
  //     activeIndexLabel: "teachers",
  //     route: `/${schoolId}/teachers`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Classes",
  //     icon: <GoDeviceDesktop />,
  //     activeIndexLabel: "classes",
  //     route: `/${schoolId}/classes`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Archives",
  //     icon: <BiCabinet />,
  //     activeIndexLabel: "archives",
  //     submenus: [
  //       {
  //         activeIndex: 3,
  //         label: " Report Cards",
  //         activeIndexLabel: "reportcards",
  //         route: `/${schoolId}/archives/classes`,
  //       },
  //     ],
  //   },
  // ];

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-8 py-5 mb-5 border-b ">
        <Logo />
      </div>

      <Menu
        menuItemStyles={{
          button: {
            [`&`]: {
              height: "40px",
            },
          },
        }}
        stye
      >
        <MenuItem
          icon={<FiUsers />}
          component={<NavLink to={`/${schoolId}/classes/${classId}`} />}
          className={`${
            activeTab?.length === 4 ||
            ["add-student", "student"]?.includes(activeTab?.[4])
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Students
        </MenuItem>

        <MenuItem
          icon={<PiBooks />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/subjects`} />
          }
          className={`${
            activeTab?.[4] === "subjects"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Subjects
        </MenuItem>

        <MenuItem
          icon={<MdOutlineCoPresent />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/attendance`} />
          }
          className={`${
            activeTab?.[4] === "attendance"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Attendance
        </MenuItem>

        <MenuItem
          icon={<GrScorecard />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/scorecards`} />
          }
          className={`${
            activeTab?.[4] === "scorecards"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Scorecards
        </MenuItem>

        <MenuItem
          icon={<GiBookshelf />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/reportcards`} />
          }
          className={`${
            ["reportcards", "students"].includes(activeTab?.[4])
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Report Cards
        </MenuItem>

        {/* <SubMenu
          label={"Archives"}
          className={`${
            activeTab?.[2] === "archives"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          icon={<BiCabinet />}
        >
          <MenuItem
            component={<NavLink to={`/${schoolId}/archives/classes`} />}
            className={`${
              activeTab?.[3] === "reportcards"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Report Cards
          </MenuItem>

          <MenuItem
            component={
              <NavLink to={`/${schoolId}/archives/graduated-students/junior`} />
            }
            className={`${
              activeTab?.[3] === "graduated-students"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Graduated Students
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/${schoolId}/archives/left-students`} />}
            className={`${
              activeTab?.[3] === "left-students"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Left Students
          </MenuItem>
        </SubMenu> */}

        {/* <MenuItem
          icon={<HiOutlineSpeakerWave />}
          component={<NavLink to={`/${schoolId}/announcements`} />}
          className={`${
            activeTab?.[2] === "announcements"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Announcement
        </MenuItem> */}

        {/* <MenuItem
          icon={<IoNotificationsOutline />}
          component={<NavLink to={`/${schoolId}/notifications`} />}
          className={`${
            activeTab?.[2] === "notifications"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Notification
          <span className="ml-5 bg-coral_red text-white text-xxs p-[3px] rounded-md">
            {totalUnreadNotif}
          </span>
        </MenuItem> */}

        {/* <MenuItem
          icon={<BiCalendar />}
          component={<NavLink to={`/${schoolId}/calendar`} />}
          className={`${
            activeTab?.[2] === "calendar"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Calendar
        </MenuItem> */}

        <MenuItem
          component={<NavLink to={`/${schoolId}/classes`} />}
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
        >
          Exit Class
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default ClassSidebar;
