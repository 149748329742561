import { useState } from "react";
import { toast } from "react-toastify";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useActivateAcademicSessionById from "../../../api/curriculum/academicSessions/useActivateAcademicSessionById";

const ActivateAcademicSession = ({
  closeSideModal,
  academicSession,
  loadingAcademicSession,
}) => {
  const { loading, request } = useActivateAcademicSessionById();
  const [text, setText] = useState("");

  async function handleDelete() {
    if (!text || text !== academicSession?.session)
      return toast("Enter correct session year");

    const isSuccessful = await request(academicSession?.academicSessionId);

    if (isSuccessful) {
      setText("");
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div>
      <div>
        <h2 className="side_page_title">Activate Academic Session</h2>
        <p className="text-sm text-independence mb-1">
          Are you sure you want to set academic session as active?
        </p>

        <p className="text-sm text-independence">
          The current active academic session will be deactivated
        </p>
      </div>

      {loadingAcademicSession ? (
        <Loader loading={loadingAcademicSession} />
      ) : (
        <div className="mt-8">
          <FormInput
            placeholder={academicSession?.session}
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
            className="mb-2"
          />

          <p className="text-sm text-independence">
            Enter "
            <span className="text-black font-bold">
              {academicSession?.session}
            </span>
            " to activate session
          </p>

          <FormButton
            title={"Activate"}
            className={"!bg-primary mt-5"}
            loading={loading}
            onClick={handleDelete}
          />

          <FormButton
            title={"Cancel"}
            className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
            onClick={closeSideModal}
          />
        </div>
      )}
    </div>
  );
};

export default ActivateAcademicSession;
