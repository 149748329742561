import { FiUsers } from "react-icons/fi";
import TableOverviewCard from "../../TableOverviewCard";

const AcademicSessionOverview = ({ totalAcademicSessions }) => {
  return (
    <>
      <div className="table_wrapper">
        <div className="table_header">
          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Sessions"}
            value={totalAcademicSessions || 0}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />
        </div>
      </div>
    </>
  );
};

export default AcademicSessionOverview;
