import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Pagination from "../../shared/components/Pagination";
import SchoolsTable from "../../shared/components/schools/SchoolsTable";
import TableOverview from "../../shared/components/schools/TableOverview";
import useFetchAllSchools from "../../shared/api/school/useFetchAllSchools";
import SchoolOwnerDetails from "../../shared/components/users/schoolOwners/SchoolOwnerDetails";

const Schools = () => {
  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const ownerId = query.get("selected");

  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Pagination state
  |--------------------------------------------------
  */
  const [page, setPage] = useState(1);

  /**
  |--------------------------------------------------
  | Pagination data
  |--------------------------------------------------
  */
  const [pagination, setPagination] = useState(null);

  /**
  |--------------------------------------------------
  | Api hook to get all teachers from server
  |--------------------------------------------------
  */
  const { schools, loading } = useFetchAllSchools(page, setPagination);

  /**
  |--------------------------------------------------
  | function to close side modal
  |--------------------------------------------------
  */
  function closeSideModal() {
    navigate(`/schools`);
  }

  return (
    <div className="page">
      <h1 className="page_title mb-5">Schools</h1>

      {/* <TeacherNavigationBar active={1} /> */}

      <div className="main_content">
        <TableOverview />

        <SchoolsTable data={schools} loading={loading} ownerId={ownerId} />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      {ownerId ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${ownerId ? "block" : ""}`}>
        <SchoolOwnerDetails ownerId={ownerId} closeSideModal={closeSideModal} />
      </div>
    </div>
  );
};
export default Schools;
