import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchAllSchools(page = 1, setPagination) {
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get(`/school`, {
        params: {
          page,
        },
      });

      setSchools(data?.data);
      if (data?.pagination && setPagination) setPagination(data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [page, setPagination]);

  useEffect(() => {
    request();
  }, [request]);

  return { loading, schools };
}
