import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { NavLink, useParams } from "react-router-dom";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../../redux/slices/school/selectors";
import {
  getAverage,
  getGrade,
  getRemark,
  getStudentClassStatus,
} from "../../../../shared/utils/functions";

import PageLoader from "../../../../shared/components/PageLoader";
import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../../shared/api/cards/useFetchStudentReportCard";
import ReportCard from "../../../../shared/components/classes/studentPreviewReportCard/ReportCard";
import useFetchStudentClassHistory from "../../../../shared/api/archives/students/useFetchStudentClassHistory";
import { BiArrowBack } from "react-icons/bi";
import { IoCloudDownloadOutline } from "react-icons/io5";

const StudentPreviewReportCard = () => {
  const reportCardRef = useRef();
  const { classId, schoolId, studentId } = useParams();

  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  const [studentDetailsLoading, studentDetails] =
    useFetchStudentBasicInfo(studentId);

  const [reportCard, scorecards, reportCardLoading] =
    useFetchStudentReportCard(calendar);

  const [classHistories, loadingClassHistories, fetchClassHistory] =
    useFetchStudentClassHistory();

  useEffect(() => {
    if (reportCard && reportCard?.term === "third") {
      fetchClassHistory({
        studentId: reportCard?.studentId,
        classId: reportCard?.classId,
        session: reportCard?.session,
      });
    }
  }, [reportCard, fetchClassHistory]);

  if (reportCardLoading || studentDetailsLoading || loadingClassHistories) {
    return <PageLoader loading={reportCardLoading || studentDetailsLoading} />;
  }

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">
              {`${studentDetails?.lastName || ""} ${
                studentDetails?.firstName || ""
              }'s`}{" "}
              Report Card
            </h1>
          </div>

          <ReactToPrint
            trigger={() => (
              <button
                className="flex items-center justify-center gap-2 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
                onClick={() => {}}
              >
                <IoCloudDownloadOutline className="text-sm" />
                <small>Download</small>
              </button>
            )}
            content={() => reportCardRef.current}
          />
        </div>

        <div className="bg-white p-3 border-b border-cultured">
          {/* <div className="main_content_header">
            <NavLink
              to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard`}
            >
              <button className="main_content_header_btn">Back to cards</button>
            </NavLink>

            <ReactToPrint
              trigger={() => (
                <button className="main_content_header_btn main_content_header_btn_active">
                  Download Report Card
                </button>
              )}
              content={() => reportCardRef.current}
            />
          </div> */}
          <NavLink
            to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard`}
          >
            <button className="px-3 py-2 flex items-center gap-2 text-yankee_blue hover:bg-primary_light_30 hover:text-primary">
              <BiArrowBack className="text-sm" />
              <small>Back to cards</small>
            </button>
          </NavLink>
        </div>

        <div className="main_content">
          <ReportCard
            ref={reportCardRef}
            school={school}
            subjects={scorecards}
            reportCard={reportCard}
            getGrade={getGrade}
            getRemark={getRemark}
            getAverage={getAverage}
            vacationDate={new Date(calendar?.vacationStartDate)?.toDateString()}
            closingDate={new Date(calendar?.closingDate)?.toDateString()}
            nextResumptionDate={new Date(
              calendar?.nextResumptionDate
            )?.toDateString()}
            endSessionRemark={getStudentClassStatus(classHistories?.[0], "end")}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentPreviewReportCard;
