import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NewAcademicSession from "../../../shared/components/curriculum/academicSession/NewAcademicSession";
import EditAcademicSession from "../../../shared/components/curriculum/academicSession/EditAcademicSession";
import AcademicSessionTable from "../../../shared/components/curriculum/academicSession/AcademicSessionTable";
import useFetchAcademicSessions from "../../../shared/api/curriculum/academicSessions/useFetchAcademicSessions";
import DeleteAcademicSession from "../../../shared/components/curriculum/academicSession/DeleteAcademicSession";
import AcademicSessionOverview from "../../../shared/components/curriculum/academicSession/AcademicSessionOverview";
import useFetchAcademicSessionById from "../../../shared/api/curriculum/academicSessions/useFetchAcademicSessionById";
import { handleToast } from "../../../shared/utils/functions";
import ActivateAcademicSession from "../../../shared/components/curriculum/academicSession/ActivateAcademicSession";

export default function AcademicSessions() {
  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const action = query.get("action");
  const selected = query.get("selected");

  /**
  |--------------------------------------------------
  | Api hook to get all academic sessions
  |--------------------------------------------------
  */
  const { loading, data: sessions, request } = useFetchAcademicSessions();

  const { loading: loadingAcademicSession, data: academicSession } =
    useFetchAcademicSessionById(selected);

  const handleEdit = (academicSession) => {
    navigate(
      `/curriculum/academic-sessions?selected=${academicSession?.academicSessionId}&action=edit`
    );
  };

  const handleDelete = (academicSession) => {
    navigate(
      `/curriculum/academic-sessions?selected=${academicSession?.academicSessionId}&action=delete`
    );
  };

  const handleActivate = (academicSession) => {
    if (academicSession?.isActive) {
      return handleToast("This session is already active");
    }

    navigate(
      `/curriculum/academic-sessions?selected=${academicSession?.academicSessionId}&action=activate`
    );
  };

  const closeSideModal = (param) => {
    if (param?.refresh) request();

    navigate(`/curriculum/academic-sessions`);
  };

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <h1 className="page_title mb-1">Academic Sessions</h1>

          <button
            className="bg-primary text-white px-3 py-1  rounded-sm"
            onClick={() => {
              navigate(
                `/curriculum/academic-sessions?action=new-academicSession`
              );
            }}
          >
            <small className="text-xs">New Session</small>
          </button>
        </div>

        <div className="main_content">
          <AcademicSessionOverview totalAcademicSessions={sessions?.length} />

          <AcademicSessionTable
            data={sessions}
            loading={loading}
            selected={selected}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleActivate={handleActivate}
          />
        </div>
      </div>

      {selected ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${selected ? "block" : ""}`}>
        {action === "new-academicSession" ? (
          <NewAcademicSession
            selected={selected}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {selected && action === "edit" && academicSession ? (
          <EditAcademicSession
            academicSession={academicSession}
            loadingAcademicSession={loadingAcademicSession}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {selected && action === "delete" && academicSession ? (
          <DeleteAcademicSession
            academicSession={academicSession}
            loadingAcademicSession={loadingAcademicSession}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {selected && action === "activate" && academicSession ? (
          <ActivateAcademicSession
            academicSession={academicSession}
            loadingAcademicSession={loadingAcademicSession}
            closeSideModal={closeSideModal}
          />
        ) : null}
      </div>
    </div>
  );
}
