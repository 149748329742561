import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../../shared/components/Loader";
import AddEvent from "../../../shared/components/calendar/AddEvent";
import SelectInput from "../../../shared/components/form/SelectInput";
import EditEvent from "../../../shared/components/calendar/EditEvent";
import NewCalendar from "../../../shared/components/calendar/NewCalendar";
import EditCalendar from "../../../shared/components/calendar/EditCalendar";
import useFetchCalendar from "../../../shared/api/calendar/useFetchCalendar";
import CalendarEvents from "../../../shared/components/calendar/CalendarEvents";
import CalendarDetails from "../../../shared/components/calendar/CalendarDetails";
import ActivateCalendar from "../../../shared/components/calendar/ActivateCalendar";
import useFetchCalendarEvents from "../../../shared/api/calendar/events/useFetchCalendarEvents";
import useFetchAcademicSessions from "../../../shared/api/curriculum/academicSessions/useFetchAcademicSessions";

const states = [
  { label: "Lagos State", name: "lagos" },
  { label: "Oyo State", name: "oyo" },
  { label: "Ogun State", name: "ogun" },
];

const terms = [
  { label: "First Term", name: "first" },
  { label: "Second Term", name: "second" },
  { label: "Third Term", name: "third" },
];

// const sessions = [
//   { label: "2023-2024", name: "2023-2024" },
//   { label: "2022-2023", name: "2022-2023" },
//   { label: "2021-2022", name: "2021-2022" },
// ];

export default function Calendar() {
  /**
  |--------------------------------------------------
  | Get query value from url parameter
  |--------------------------------------------------
  */
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const term = query.get("term");
  const state = query.get("state");
  const action = query.get("action");
  const session = query.get("session");
  const eventId = query.get("eventId");

  const navigate = useNavigate();

  /**
  |--------------------------------------------------
  | Api hook to fetch calendar
  |--------------------------------------------------
  */
  const {
    calendar,
    loading,
    request: getCalendar,
    clear: clearCalendar,
  } = useFetchCalendar();

  /**
  |--------------------------------------------------
  | Api hook to get all academic sessions
  |--------------------------------------------------
  */
  const { data: sessions } = useFetchAcademicSessions();

  const [events] = useFetchCalendarEvents(calendar?.calendarId);

  /**
  |--------------------------------------------------
  | useEffect to fetch calendar when query changes
  |--------------------------------------------------
  */
  useEffect(() => {
    if (session && state && term) {
      getCalendar(state, session, term);
    }
  }, [state, session, term, getCalendar]);

  /**
  |--------------------------------------------------
  | function to select state
  |--------------------------------------------------
  */
  function handleSelectState(e) {
    clearCalendar();
    navigate(`/curriculum/calendar?state=${e.currentTarget.value}`);
  }

  /**
  |--------------------------------------------------
  | function to select term only if session is selected
  |--------------------------------------------------
  */
  function handleSelectTerm(e) {
    if (!session) return toast("Select a session");

    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${e.currentTarget.value}`
    );
  }

  /**
  |--------------------------------------------------
  | function to select session only if state is selected
  |--------------------------------------------------
  */
  function handleSelectSession(e) {
    if (!state) return toast("Select a state");

    clearCalendar();
    navigate(
      `/curriculum/calendar?state=${state}&session=${e.currentTarget.value}`
    );
  }

  /**
  |--------------------------------------------------
  | function to close calendar side modal
  |--------------------------------------------------
  */
  function closeSideModal() {
    navigate(`/curriculum/calendar`);
  }

  /**
  |--------------------------------------------------
  | function to open edit calendar side modal
  |--------------------------------------------------
  */
  function handleEditCalendar() {
    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${term}&action=edit-calendar`
    );
  }

  const handleActivate = () => {
    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${term}&action=activate-calendar`
    );
  };

  /**
  |--------------------------------------------------
  | function to close edit calendar modal and refresh calendar
  |--------------------------------------------------
  */
  async function closeEditCalendar() {
    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${term}`
    );

    await getCalendar(state, session, term);
  }

  /**
  |--------------------------------------------------
  | function to open new calendar event modal
  |--------------------------------------------------
  */
  function handleAddEvent() {
    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${term}&action=add-event`
    );
  }

  /**
  |--------------------------------------------------
  | function to open edit calendar event modal
  |--------------------------------------------------
  */
  function handleEditEvent(event) {
    navigate(
      `/curriculum/calendar?state=${state}&session=${session}&term=${term}&action=edit-event&eventId=${event?.calendarEventId}`
    );
  }

  return (
    <div className="page">
      <div className="mb-5 flex items-center justify-between">
        <h1 className="page_title mb-1">Calendar</h1>

        <button
          className="bg-primary text-white px-3 py-1  rounded-sm"
          onClick={() => {
            clearCalendar();
            navigate(`/curriculum/calendar?action=new-calendar`);
          }}
        >
          <small className="text-xs">New Calendar</small>
        </button>
      </div>

      <div className="main_content mb-5 px-3">
        <div className="mb-5">
          <h1 className="mb-1 text-lg">Manage Calendar</h1>
          <p className="text-sm text-independence">
            Add, Edit and Review calendar sessions based on state
          </p>
        </div>

        <div>
          <div className="grid grid-cols-2 xs:grid-cols-3 gap-1 sm:flex-1 max-w-xl">
            <SelectInput
              options={states}
              className="mb-0"
              value={state || ""}
              placeholder={"Select state"}
              handleSelect={handleSelectState}
            />

            <SelectInput
              className="mb-0"
              options={sessions}
              value={session || ""}
              placeholder={"Select session"}
              optionKeyLabel={"session"}
              handleSelect={handleSelectSession}
            />

            <SelectInput
              options={terms}
              className="mb-0"
              value={term || ""}
              placeholder={"Select term"}
              handleSelect={handleSelectTerm}
            />
          </div>
        </div>
      </div>

      <div className="main_content mb-5">
        {loading ? <Loader loading={loading} /> : null}

        {!loading && calendar ? (
          <CalendarDetails
            calendar={calendar}
            handleEditCalendar={handleEditCalendar}
            handleAddEvent={handleAddEvent}
            handleActivate={handleActivate}
          />
        ) : (
          <p className="text-center text-sm text-independence">
            No calendar record to display
          </p>
        )}
      </div>

      <CalendarEvents
        events={events}
        calendar={calendar}
        className={"block xl:hidden"}
        handleEditEvent={handleEditEvent}
        containerStyle={"sm:grid-cols-2 sm:grid sm:gap-5"}
      />

      {action ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar !p-0 ${action ? "block" : ""}`}>
        {action === "new-calendar" ? (
          <NewCalendar states={states} closeSideModal={closeSideModal} />
        ) : null}

        {action === "edit-calendar" && calendar ? (
          <EditCalendar
            calendar={calendar}
            closeSideModal={closeEditCalendar}
          />
        ) : null}

        {action === "add-event" && calendar ? (
          <AddEvent calendar={calendar} closeSideModal={closeEditCalendar} />
        ) : null}

        {action === "edit-event" && calendar ? (
          <EditEvent
            calendar={calendar}
            closeSideModal={closeEditCalendar}
            eventId={eventId}
          />
        ) : null}

        {action === "activate-calendar" && calendar ? (
          <ActivateCalendar
            eventId={eventId}
            calendar={calendar}
            loadingCalendar={loading}
            closeSideModal={closeEditCalendar}
          />
        ) : null}

        {!action ? (
          <CalendarEvents
            events={events}
            calendar={calendar}
            className={"hidden xl:block"}
            handleEditEvent={handleEditEvent}
          />
        ) : null}
      </div>
    </div>
  );
}
