import { FaEdit } from "react-icons/fa";
// import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";
import { formatCurrency } from "../../../utils/functions";

const InvoiceTable = ({ data, loading }) => {
  const navigate = useNavigate();

  function handleNavigate(params) {
    navigate(`/payment/invoices/${params?.invoiceId}`);
  }

  const handleEditInvoice = (item) => {
    navigate(`/payment/invoices?selected=${item?.invoiceId}&action=edit`);
  };

  return (
    <div className="table_wrapper">
      <div className="">
        {/* table header */}
        <div className="grid grid-cols-6 border-b">
          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Invoice Number</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Invoice Period</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Invoice Owner</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Amount</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Status</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Action</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable message={"No teacher record found"} />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-6 border-b table_row hover:bg-primary_light_20`}
            key={i}
          >
            <div className="border-r p-3" onClick={() => handleNavigate(item)}>
              <p className="text-independence text-sm capitalize underline">
                {item?.invoiceNumber}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm line-clamp-1">
                {new Date(item?.paymentDueDate)?.toLocaleDateString()} -{" "}
                {new Date(item?.paymentExpiryDate)?.toLocaleDateString()}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {new Date(item?.paymentDueDate)?.toLocaleDateString()}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                ₦{formatCurrency(item?.totalAmount)}
              </p>
            </div>

            <div className="border-r p-3">
              <div
                className={`text-independence text-sm w-full ${
                  item?.status === "paid"
                    ? "item_paid"
                    : item?.status === "pending"
                    ? "item_pending"
                    : item?.status === "overdue"
                    ? "item_overdue"
                    : ""
                }`}
              >
                {item?.status}
              </div>
            </div>

            <div className="p-3" onClick={() => handleEditInvoice(item)}>
              <FaEdit />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvoiceTable;
