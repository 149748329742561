import CalendarEvent from "./CalendarEvent";
import moment from "moment";

const CalendarEvents = ({
  calendar,
  className,
  containerStyle,
  events,
  handleEditEvent,
}) => {
  if (!calendar) return null;

  return (
    <div className={`py-4 px-3 bg-ghost_white rounded-sm ${className}`}>
      <h5 className="font-semibold text-lg text-yankee_blue">
        Calendar Highlights
      </h5>

      <div className={`bg-white rounded-sm mt-5 ${containerStyle}`}>
        <CalendarEvent
          date={moment(calendar?.resumptionDate).format("DD/MM/YY")}
          title={"Resumption Date"}
          subtitle={`School resumes ${calendar?.term} term academic session`}
        />

        <CalendarEvent
          date={moment(calendar?.closingDate).format("DD/MM/YY")}
          title={"Closing Date"}
          subtitle={`School closes ${calendar?.term} term academic session`}
        />

        <CalendarEvent
          date={moment(calendar?.vacationStartDate).format("DD/MM/YY")}
          title={"Vacation Begins"}
          subtitle={`Vacation starts for ${calendar?.term} term`}
        />

        <CalendarEvent
          date={moment(calendar?.vacationEndDate).format("DD/MM/YY")}
          title={"Vacation Ends"}
          subtitle={`Vacation Ends for ${calendar?.term} term`}
        />

        <CalendarEvent
          date={moment(calendar?.nextResumptionDate).format("DD/MM/YY")}
          title={"Next Term Begins"}
          subtitle={"The next term resumes"}
        />
      </div>

      {events?.length ? (
        <div className="mt-5">
          <h5 className="font-semibold text-lg text-yankee_blue">
            Calendar Events
          </h5>

          <div className={`bg-white rounded-sm mt-5 ${containerStyle}`}>
            {events?.map((event, i) => (
              <CalendarEvent
                key={i}
                date={moment(event?.startDate).format("DD/MM/YY")}
                title={"Event Title"}
                subtitle={event?.title}
                onClick={() => handleEditEvent(event)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default CalendarEvents;
