import { useCallback, useState } from "react";

import { handleToast } from "../../../utils/functions";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useUpdateEvent() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);

      const res = await client.patch(
        "/calendar-event/" + payload?.calendarEventId,
        payload
      );

      handleToast(res.data?.message);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { loading, request };
}
