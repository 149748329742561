import { useNavigate } from "react-router-dom";

import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";

const SchoolOwnersTable = ({ loading, data, ownerId }) => {
  const navigate = useNavigate();

  function handleNavigateToSchool(param) {
    navigate(`/${param?.school?.schoolId}/dashboard`);
  }

  function handleNavigateToProfile(params) {
    navigate(`/users/school-owners?selected=${params?.userId}`);
  }

  return (
    <div className="table_wrapper">
      <div className="min-w-[900px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">School ID</h5>
          </div>

          <div className="p-3 border-r col-span-2">
            <h5 className="font-semibold text-sm">Email</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Phone</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Date Joined</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No teacher record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-8 border-b table_row ${
              ownerId === item?.userId ? "active_table_row" : ""
            }`}
            key={i}
          >
            <div
              className="col-span-2 border-r p-3"
              onClick={() => handleNavigateToProfile(item)}
            >
              <p className="text-independence text-sm capitalize">
                {item?.lastName} {item?.firstName}
              </p>
            </div>

            <div
              className="border-r p-3 col-span-2"
              onClick={() => handleNavigateToSchool(item)}
            >
              <p className="text-independence text-sm capitalize mb-1">
                {item?.schoolId}
              </p>
            </div>

            <div className="p-3 border-r col-span-2">
              <p className="text-independence text-sm">{item?.email}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.phone}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SchoolOwnersTable;
